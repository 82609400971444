import React from 'react';
import { View, Text, Image } from 'react-native-web';
import { connect } from 'react-redux';

import { editNews } from '../../api/models/newsModels';
import {NEWS_PATCH} from "./api/newsEndpoints";
import patch from '../../api/methods/patch';

import NavBar from "../../components/NavBar";
import {
	Card,
    Button,
    ButtonWithSpinner,
    InputTextImage,
    ConditionalTextRender,
    InformationDialogue,
    CharCount,
} from "../../components";

import {
    cardContainer,
    cardSendButtonContainer,
    cardSendButtonText,
    cardErrorText,
    cardTitleStyle,
    cardInputContainerStyle,
    cardRowStyle,
    cardRowText
} from "../../constants/CardStyles";

class NewsEditScreen extends React.Component {

    INPUT_TITLE = 'title';
    INPUT_SHORT_DESCRIPTION = 'shortDescription';
    INPUT_LONG_DESCRIPTION = 'longDescription';
    INPUT_EXTERNAL_LINK = 'externalLink';

    state = {
        uniqueId: '',
        title: '',
        shortDescription: '',
        longDescription: '',
        image: '',
        externalLink: '',

        haveNewImage: false,
        file: null,
        imageStripped: null,

        uploading: false,

        inputError: '',

        requestError: '',
        requestSuccess: '',

        requestSuccessModalVisible: false,
        requestErrorModalVisible: false,
    };

    handleInput = (value, type) => {
        switch (type) {
            case this.INPUT_TITLE:
                this.setState({ title: value });
                break;

            case this.INPUT_SHORT_DESCRIPTION:
                this.setState({ shortDescription: value });
                break;

            case this.INPUT_LONG_DESCRIPTION:
                this.setState({ longDescription: value });
                break;

            case this.INPUT_EXTERNAL_LINK:
                this.setState({ externalLink: value });
                break;

            default:
                break;
        }
    };

    handleFileSelected = (e) => {
        if (this.state.uploading) return;

        const file = e.target.files[0];
        this.setState({ file: file });

        let reader = new FileReader();
        reader.onloadend = () => {

            //NOTE(Celso): From https://stackoverflow.com/questions/24289182/how-to-strip-type-from-javascript-filereader-base64-string
            let base64result = reader.result.split(',')[1];

            this.setState({
                image: reader.result,
                imageStripped: base64result,
                haveNewImage: true
            })
        };

        reader.readAsDataURL(file);
    };

    handleSend = async () => {
        if (this.state.uploading) return;
        this.setState({ uploading: true, requestError: '', requestSuccess: '', });

        const { uniqueId, title, shortDescription, longDescription, externalLink, imageStripped, haveNewImage } = this.state;

        let dataModel = editNews(
            uniqueId,
            title,
            shortDescription,
            longDescription,
            externalLink,
            haveNewImage === true ? imageStripped : null,
            haveNewImage
        );

        try {

            await patch(NEWS_PATCH, dataModel, this.props.authToken);

            this.setState({
                requestSuccess: 'Notícia editada com sucesso!',
                requestSuccessModalVisible: true,
            })

        } catch (e) {
            this.setState({
                requestError: 'Erro ao editar notícia!',
                requestErrorModalVisible: true
            })
        }

        this.setState({ uploading: false });
    };

    componentWillMount() {
        const { uniqueId, title, shortDescription, longDescription, image, externalLink } = this.props.location.state;

        this.setState({
            uniqueId: uniqueId,
            title: title,
            shortDescription: shortDescription,
            longDescription: longDescription,
            image: image,
            externalLink: externalLink,
        });
    }

    render() {
        return(
            <NavBar
                title={'Noticias - Editar'}
                showBackButton={true}
            >
                <InformationDialogue
                    isModalVisible={this.state.requestSuccessModalVisible}
                    titleText={'Éxito'}
                    descriptionText={'¡La noticia se ha editado con éxito!'}
                    buttons={[
                        {
                            text: 'Volver a la lista',
                            onPress: () => {
                                this.setState({ requestSuccessModalVisible: false });
                                this.props.history.push('/news');
                            }
                        },
                        {
                            text: 'Continuar',
                            onPress: () => {
                                this.setState({ requestSuccessModalVisible: false });
                            }
                        }
                    ]}
                />

                <InformationDialogue
                    isModalVisible={this.state.requestErrorModalVisible}
                    titleText={'Error'}
                    descriptionText={'Error al editar la noticia. Vuelve a intentarlo más tarde o ponte en contacto con el servicio de asistencia.'}
                    onClose={() => this.setState({ requestErrorModalVisible: false })}
                />

                <Card containerProps={cardContainer}>

                    <Text style={cardTitleStyle}>
                        Editando noticia
                    </Text>

                    <InputTextImage
                        placeholder={'título da noticia'}
                        label={'Título: '}
                        onChangeText={(value) => this.handleInput(value, this.INPUT_TITLE)}
                        value={this.state.title}
                        propsContainerStyle={cardInputContainerStyle}
                    />

                    <InputTextImage
                        label={'Descripción corta: '}
                        onChangeText={(value) => this.handleInput(value, this.INPUT_SHORT_DESCRIPTION)}
                        value={this.state.shortDescription}
                        placeholder={''}
                        propsContainerStyle={[cardInputContainerStyle, { height: 95, alignItems: 'flex-start' }]}
                        propsInputStyle={{ height: 80 }}
                        multiline={true}
                        charCount={true}
                        maxLength={280}
                    />

                    <InputTextImage
                        label={'Descripción larga: '}
                        onChangeText={(value) => this.handleInput(value, this.INPUT_LONG_DESCRIPTION)}
                        value={this.state.longDescription}
                        placeholder={''}
                        propsContainerStyle={[cardInputContainerStyle, { height: 120, alignItems: 'flex-start' }]}
                        propsInputStyle={{ height: 110 }}
                        multiline={true}
                    />

                    <InputTextImage
                        label={'Link: '}
                        onChangeText={(value) => this.handleInput(value, this.INPUT_EXTERNAL_LINK)}
                        value={this.state.externalLink}
                        placeholder={''}
                        propsContainerStyle={cardInputContainerStyle}
                    />

                    <View style={cardRowStyle}>
                        <Text style={cardRowText}>Imagen: </Text>
                        <input type={"file"} onChange={this.handleFileSelected} />
                    </View>

                    {
                        this.state.image &&
                        <View style={[cardRowStyle, { flexDirection: 'column', alignItems: 'flex-start' }]}>
                            <Text style={cardRowText}>Preview Imagen: </Text>
                            <Image
                                source={this.state.image}
                                style={{ height: 100, width: 200 }}
                                resizeMode={'contain'}
                            />
                        </View>

                    }

                    <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>

                        <ConditionalTextRender
                            text={this.state.inputError}
                            textPropsStyle={cardErrorText}
                        />

                        <ConditionalTextRender
                            text={this.state.requestError}
                            textPropsStyle={cardErrorText}
                        />

                        <ButtonWithSpinner
                            isLoading={this.state.uploading}
                            text={'Enviar'}
                            onPress={this.handleSend}
                            containerPropsStyle={cardSendButtonContainer}
                            textPropsStyle={cardSendButtonText}
                        />
                    </View>
                </Card>
            </NavBar>
        )
    }
}

const mapStateToProps = state => {
    return {
        authToken: state.auth.authData.IdToken
    }
};

export default connect(mapStateToProps)(NewsEditScreen);
