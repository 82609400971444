import {
    POST_UPDATE_POSTS,
    POST_UPDATE_POSTS_FAIL,
    POST_UPDATE_POSTS_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    postText: '',
    isSending: false,
    error: '',
    success: '',

    isFecthing: false,
    refreshing: false, 
    updateError: '',
    posts: [],
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        
        case POST_UPDATE_POSTS:
            return {
                ...state, 
                isFetching: true,
                updateError: '',
            }
            
        case POST_UPDATE_POSTS_SUCCESS:
            return {
                ...state, 
                isFetching: false,
                posts: action.payload
            }

        case POST_UPDATE_POSTS_FAIL: 
            return {
                ...state, 
                isFetching: false,
                updateError: action.payload,                
            }

        default: 
            return state;
    }
}