import React from 'react';
import { View, Image, ActivityIndicator } from 'react-native-web';
import PropTypes from 'prop-types';
import get from "../api/methods/get";
import {SPONSOR_GET_LOGO} from "../api/apiEndpoints";

class ImageDisplay extends React.Component {

    state = {
        isLoading: false,
        base64ToRender: null,
    };

    fetchImage = async () => {
        if (this.state.isLoading) return;
        this.setState({ isLoading: true });

        try {
            let response = await get(this.props.imageUrl, this.props.authToken);
            let responseJson = await response.json();

            this.setState({ base64ToRender: responseJson.data });
        } catch (e) {
            console.log(e);
        }

        this.setState({ isLoading: false });
    };

    componentDidMount() {
        this.fetchImage()
    }

    render() {

        return (
            <View style={{ minHeight: 128, minWidth: 128 }}>

                {
                    this.state.isLoading === true &&
                        <ActivityIndicator size={'large'} />
                }

                {
                    this.state.imageToRender !== null &&
                        <Image
                            source={{ uri: `data:image/jpeg;base64,${this.state.base64ToRender}` }}
                            style={[{ height: 128, width: 128 }, this.props.imageStyleProps]}
                            resizeMode={'contain'}
                        />
                }

            </View>
        )
    }

}

ImageDisplay.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    authToken: PropTypes.string.isRequired,
    imageStyleProps: PropTypes.object
};

export { ImageDisplay };
