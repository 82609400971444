import React from 'react';
import {View} from 'react-native';
import {Router, Switch, Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import createBrowserHistory from 'history/createBrowserHistory';

import LoginScreen from '../screens/LoginScreen';

import HomeScreen from "../screens/HomeScreen";
import MuralScreen from '../screens/mural/MuralScreen'

import NewsScreen from '../screens/news/NewsScreen';
import NewsAddScreen from '../screens/news/NewsAddScreen';
import NewsEditScreen from '../screens/news/NewsEditScreen';

import EverydayExcellenceScreen from '../screens/everydayExcellence/EverydayExcellenceScreen';
import EverydayExcellenceDetail from "../screens/everydayExcellence/EverydayExcellenceDetail";
import EverydayExcellenceMonthDetail from "../screens/everydayExcellence/EverydayExcellenceMonthDetail";
import EverydayExcellenceGeneralGraph from "../screens/everydayExcellence/EverydayExcellenceGeneralGraph";

const history = createBrowserHistory();

class AppRouter extends React.Component {

    render() {
        if (history.location.pathname !== '/') {
            if (this.props.authData === undefined || this.props.authData === null) {
                history.push('/');
            }
        }

        return (
            <Router history={history}>
                <Switch>
                    <Route
                        exact path={'/home'}
                        component={HomeScreen}
                    />

                    <Route
                        exact path={'/mural'}
                        component={MuralScreen}
                    />

                    <Route
                        exact path={'/news'}
                        component={withRouter(NewsScreen)}
                    />

                    <Route
                        exact path={'/news/add'}
                        component={withRouter(NewsAddScreen)}
                    />

                    <Route
                        exact path={'/news/edit'}
                        component={withRouter(NewsEditScreen)}
                    />

                    <Route
                        exact path={'/everydayexcellence'}
                        component={withRouter(EverydayExcellenceScreen)}
                    />

                    <Route
                        path={'/everydayexcellence/details'}
                        component={withRouter(EverydayExcellenceDetail)}
                    />

                    <Route
                        path={'/everydayexcellence/month/details'}
                        component={withRouter(EverydayExcellenceMonthDetail)}
                    />

                    <Route
                        path={'/everydayexcellence/data'}
                        component={withRouter(EverydayExcellenceGeneralGraph)}
                    />

                    <Route
                        path='/'
                        component={LoginScreen}
                    />

                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return {
        authData: state.auth.authData
    }
};

export default connect(mapStateToProps, null)(AppRouter);

// export default connect(mapStateToProps, null)(AppRouter);
