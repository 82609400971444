import React from 'react';
import {
    View,
    Text,
    StyleSheet
} from 'react-native';
import PropTypes from 'prop-types';
import Colors from "../../constants/Colors";

const Header = ({headerText, textPropsStyle, containerPropsStyle}) => {
    const {containerStyle, textStyle} = styles;

    return (
        <View style={[containerStyle, containerPropsStyle]}>
            <Text style={[textStyle, textPropsStyle]}>
                {headerText}
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    containerStyle: {
        justifyContent: 'center',
        backgroundColor: Colors.navBarColor,

    },
    textStyle: {
        marginLeft: 20,
        color: 'white',
        fontSize: 28
    }
});

Header.propTypes = {
    headerText: PropTypes.string.isRequired,
    textPropsStyle: PropTypes.object,
    containerPropsStyle: PropTypes.object
};

export {Header};