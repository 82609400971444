export const drawerConfig = [
    {
        name: 'Mural',
        route: '/mural'
    },
    {
        name: 'Noticias',
        route: '/news'
    },
    {
        name: 'Everyday Excellence',
        route: '/everydayexcellence'
    },
];
