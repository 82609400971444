import {mainUrl, guruId} from "../../../api/apiSettings";

// GET - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/news/{guruId}/admin
export const NEWS_GET = `${mainUrl}/news/${guruId}/admin`;

// PATCH - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/news/{guruId}/admin
export const NEWS_PATCH = `${mainUrl}/news/${guruId}/admin`;

// POST - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/news/{guruId}/admin
export const NEWS_POST = `${mainUrl}/news/${guruId}/admin`;

// DELETE - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/news/{guruId}/admin/{uniqueId}
export const NEWS_DELETE = (uniqueId) => `${mainUrl}/news/${guruId}/admin/${uniqueId}`;

// GET - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/news/{guruId}/admin/image/{uniqueId}
export const NEWS_GET_IMAGE = (uniqueId) => `${mainUrl}/news/${guruId}/admin/image/${uniqueId}`;
