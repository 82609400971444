import React from 'react';
import {
    View,
    Image,
    Text,
    StyleSheet
} from 'react-native-web';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import {Card, ImageDisplay} from '../../components';
import {Button, ButtonWithSpinner, Title, ConfirmationDialogue} from "../../components";

import deleteMethod from '../../api/methods/delete';
import {NEWS_GET_IMAGE, NEWS_DELETE} from "./api/newsEndpoints";

class NewsListComponent extends React.Component {

    state = {
        isRemoving: false,
        isModalVisible: false,
    };

    handleRemove = async () => {
        if (this.state.isRemoving) return;
        this.setState({ isRemoving: true });

        try {
            await deleteMethod(NEWS_DELETE(this.props.newsData.uniqueId), this.props.authToken);
            this.props.onRemove(true);
        } catch (e) {
            console.log('error', e);
            this.props.onRemove(false);
        }

        this.setState({ isRemoving: false });
    };

    render() {
        const { uniqueId, title, shortDescription, longDescription, image, externalLink } = this.props.newsData;

        return (
            <Card containerProps={{ margin: 12, flexDirection: 'row' }}>

                <ConfirmationDialogue
                    isModalVisible={this.state.isModalVisible}
                    onClose={() => this.setState({ isModalVisible: false })}
                    onConfirm={() => {
                        this.handleRemove();
                        this.setState({ isModalVisible: false })
                    }}
                    descriptionText={'¿Estás seguro de que de quieres borrar esta noticia?'}
                />

                <View style={{ width: '70%' }}>
                    <Title title={'Título: '} description={title} />
                    <Text style={styles.title}> {'Imagen: '} </Text>
                    <ImageDisplay
                        imageUrl={NEWS_GET_IMAGE(uniqueId)}
                        authToken={this.props.authToken}
                        style={{ height: 100, width: 200 }}
                    />

                    <Text style={styles.title}> {'Descripción corta: '} </Text>
                    <Text> {shortDescription} </Text>

                    <Text style={styles.title}> {'Descripción larga: '} </Text>
                    <Text> {longDescription} </Text>
                </View>
                <View style={{ width: '30%' }}>
                    <Button
                        text={'Editar'}
                        onPress={() => this.props.history.push('/news/edit', this.props.newsData)}
                        containerPropsStyle={{ height: 30, margin: 10 }}
                        textPropsStyle={{fontSize: 16}}
                    />
                    <ButtonWithSpinner
                        isLoading={this.state.isRemoving}
                        text={'Eliminar'}
                        onPress={() => this.setState({ isModalVisible: true })}
                        containerPropsStyle={{ height: 30, margin: 10 }}
                        textPropsStyle={{fontSize: 16}}
                    />
                </View>
            </Card>
        )
    }
}

const styles = StyleSheet.create({
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
});

NewsListComponent.propTypes = {
    newsData: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired
};

export default withRouter(NewsListComponent);
