import React, {Component} from 'react';
import {Provider} from 'react-redux';
import configureStore from './redux/store';
import AppRouter from "./navigation/AppRouter";

class App extends Component {

    store = configureStore();

    render() {
        return (
            <Provider store={this.store}>
                <AppRouter />
            </Provider>
        );
    }
}

export default App;