import React from 'react';
import {
    Image,
    TouchableOpacity,
    StyleSheet
} from 'react-native';
import PropTypes from 'prop-types';

const ButtonImage = ({imageSource, onPress, imagePropsStyle}) => {
    return (
        <TouchableOpacity onPress={() => onPress()}>
            <Image
                style={[styles.imageStyle, imagePropsStyle]}
                resizeMode='contain'
                source={imageSource}
            />
        </TouchableOpacity>
    )
};

const styles = StyleSheet.create({
    imageStyle: {
        width: 64,
        height: 64
    }
});

ButtonImage.propTypes = {
    imageSource: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    imagePropsStyle: PropTypes.object
};

export {ButtonImage};
