import React from 'react';
import {
    View
} from 'react-native-web';
import PropTypes from 'prop-types';

import { Button } from './Button';

const AddButton = ({ history, route }) => {

    return (
        <View style={{ position: 'fixed', flex: 1, bottom: 25, right: 25}}>
            <View style={{ alignItems: 'center', justifyContent: 'center'}}>
                <Button
                    text={'\uFF0B'}
                    onPress={() => history.push(route)}
                    containerPropsStyle={{ width: 50, height: 50, borderRadius: 90, alignItems: 'center', justifyContent: 'center' }}
                    textPropsStyle={{ fontWeight: 'bold' }}
                />
            </View>
        </View>
    )
};

AddButton.propTypes = {
    history: PropTypes.object.isRequired,
    route: PropTypes.string.isRequired
};

export { AddButton };
