import React from 'react';
import {
    ImageBackground,
    Text,
    TouchableOpacity,
    StyleSheet
} from 'react-native'
import PropTypes from 'prop-types';

/*  NOTE(Celso):
    The only difference from this component to the ButtonImageText component is
    that here we use ImageBackground, this means that the text will be in front
    of the image when it's rendered.
 */

const ButtonImageWithText = ({
                             imageSrc,
                             text,
                             onPress,
                             propsContainerStyle,
                             propsImageStyle,
                             propsTextStyle
                         }) => {

    return (
        <TouchableOpacity
            style={[styles.containerStyle, propsContainerStyle]}
            onPress={onPress}
        >
            <ImageBackground
                source={imageSrc}
                style={[styles.imageStyle, propsImageStyle]}
                resizeMode={'contain'}
            >
                <Text style={[styles.textStyle, propsTextStyle]}>
                    {text}
                </Text>
            </ImageBackground>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    containerStyle: {
        width: 64,
        height: 64
    },
    imageStyle: {
        width: 64,
        height: 64
    },
    textStyle: {
        textAlign: 'center' ,
        color: 'white',
        fontWeight: 'bold'
    }
});

ButtonImageWithText.propTypes = {
    imageSrc: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    propsContainerStyle: PropTypes.object,
    propsImageStyle: PropTypes.object,
    propsTextStyle: PropTypes.object
};

export { ButtonImageWithText };