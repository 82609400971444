import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer';
import ScheduleReducer from './ScheduleReducer';

import PostReducer from './PostReducer';


export default combineReducers({
    auth: AuthReducer,
    schedule: ScheduleReducer,

    post: PostReducer
})
