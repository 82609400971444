import React from 'react';
import {
    Image,
    Text,
    TouchableOpacity,
    StyleSheet
} from 'react-native'
import PropTypes from 'prop-types';
import Colors from '../../constants/Colors';

const ButtonImageText = ({
                             imageSrc,
                             text,
                             onPress,
                             propsContainerStyle,
                             propsImageStyle,
                             propsTextStyle
                         }) => {
    return (
        <TouchableOpacity
            style={[styles.containerStyle, propsContainerStyle]}
            onPress={onPress}
        >
            {
                imageSrc !== undefined &&
                <Image
                    source={imageSrc}
                    style={[styles.imageStyle, propsImageStyle]}
                    resizeMode={'contain'}
                />
            }

            <Text style={[styles.textStyle, propsTextStyle]}>
                {text}
            </Text>
        </TouchableOpacity>
    )
};

const styles = StyleSheet.create({
    containerStyle: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: 40,
        maxHeight: 40,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderColor: Colors.drawerLineColor,
    },
    imageStyle: {
        height: 24,
        width: 24,
        marginRight: 10,
        marginLeft: 10
    },
    textStyle: {
        marginLeft: 8,
        textAlign: 'left',
        color: 'white',
        fontWeight: 'bold'
    }
});

ButtonImageText.propTypes = {
    // imageSrc: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    propsContainerStyle: PropTypes.object,
    propsImageStyle: PropTypes.object,
    propsTextStyle: PropTypes.object
};

export {ButtonImageText};