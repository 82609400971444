import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGIN_USER,
    LOGOUT_USER,
} from '../actions/types';

const INITIAL_STATE = {
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    authData: null,

    userEmail: '',
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case LOGOUT_USER:
            return { ...state };

        case LOGIN_USER:
            return {
                ...state,
                isFetching: true,
                isSuccess: false,
                errorMessage: ''
            };

        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                isFetching: false,

                authData: action.payload.authData,
                userEmail: action.payload.email
            };

        case LOGIN_USER_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
                isFetching: false
            };

        default:
            return state;
    }
}
