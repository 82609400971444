import React from 'react';
import {
  ActivityIndicator
} from 'react-native';
import PropTypes from 'prop-types';
import { Button } from './Button';

const ButtonWithSpinner = ({ isLoading, size, ...rest }) => {

  if (isLoading) {
    return (
      <ActivityIndicator size={size || 'large'} />
    )
  }

  return <Button {...rest} />
};

ButtonWithSpinner.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export { ButtonWithSpinner };