import React from 'react';
import {
    View,
    Text,
    StyleSheet
} from 'react-native-web';
import PropTypes from 'prop-types';
import Modal from 'modal-enhanced-react-native-web';

import DialogueButtons from './DialogueButtons';

const InformationDialogue = (props) => {

    const {
        isModalVisible,
        onClose,
        descriptionText,
        titleText,
        buttons
    } = props;

    return (
        <Modal
            isVisible={isModalVisible}
            style={{
                flex: 1,
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    width: 400,
                    backgroundColor: 'white',
                    borderRadius: 8,
                    padding: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >

                <Text style={styles.title}>
                    { titleText }
                </Text>

                <Text style={styles.description}>
                    { descriptionText }
                </Text>

                <View
                    style={{ flexDirection: 'row', justifyContent: 'space-around' }}
                >
                    <DialogueButtons
                        buttons={buttons}
                        onClose={onClose}
                    />
                </View>
            </View>
        </Modal>
    )
};

const styles = StyleSheet.create({
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 22,
        marginBottom: 12
    },
    description: {
        textAlign: 'center',
        marginBottom: 12,
    },
});

InformationDialogue.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    titleText: PropTypes.string.isRequired,
    descriptionText: PropTypes.string.isRequired,
    buttons: PropTypes.array,
    onClose: PropTypes.func
};

export {InformationDialogue};
