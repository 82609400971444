import React from 'react';
import {
    View,
    Text,
    ActivityIndicator,
    StyleSheet
} from 'react-native';
import PropTypes from 'prop-types';

const SpinnerControl = ({ isFetching, text, size }) => {
    if (!isFetching) return null;
    return(
        <View style={styles.containerStyle}>
            {
                text !== undefined &&
                    <Text style={{ textAlign: 'center', fontSize: 18 }}>
                        {text}
                    </Text>
            }

            <ActivityIndicator size={size || 'large'} />
        </View>
    )
};

const styles = StyleSheet.create({
    containerStyle: {
        justifyContent: 'center',
        alignItems: 'center'
    }
});

SpinnerControl.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    size: PropTypes.string
};

export { SpinnerControl };
