import React from 'react';
import {
    Text,
    StyleSheet
} from 'react-native-web';
import PropTypes from 'prop-types';

const Bold = (props) => {

    const { label, description } = props;
    const { boldStyle } = props;

    return (
        <Text> <Text style={[styles.title, boldStyle]}> {label} </Text> {description} </Text>
    )
};

Bold.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.any
};

const styles = StyleSheet.create({
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
});

export { Bold }
