import React from 'react';
import {
    View,
    Image,
    Text,
    StyleSheet
} from 'react-native';
import PropTypes from 'prop-types';

/*  User Avatar
  Give a AvatarUri and a UserName as props for the object,
  if the AvatarUri is not defined or null, it will use the
  UserName to create a simple avatar using the initials.
*/

class UserAvatar extends React.Component {

    renderInitials() {
        let name = this.props.userName;

        let initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

        return (
            <View style={[styles.initialsStyle, {backgroundColor: this.props.userAvatarColor}, this.props.avatarPropsStyle]}>
                <Text style={{color: 'white', fontSize: 28}}>
                    {initials}
                </Text>
            </View>
        )
    }

    renderAvatar() {
        const { userAvatarUri, userAvatarData } = this.props;

        if (userAvatarData !== undefined && userAvatarData !== null && userAvatarData !== '') {
            let source = { uri: 'data:image/jpeg;base64,' + userAvatarData };
            return (
                <Image
                    style={[styles.avatarStyle, this.props.avatarPropsStyle]}
                    // resizeMode='cover'
                    source={source}
                />
            )
        }

        if (userAvatarUri === undefined || userAvatarUri === null) {
            return this.renderInitials();
        }

        return (
            <Image
                style={[styles.avatarStyle, this.props.avatarPropsStyle]}
                // resizeMode='cover'
                source={{ uri: userAvatarUri }}
            />
        )
    }

    render() {
        return (
            <View>
                {this.renderAvatar()}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    avatarStyle: {
        width: 64,
        height: 64,
        borderRadius: 45
    },
    initialsStyle: {
        width: 64,
        height: 64,
        borderRadius: 45,
        alignItems: 'center',
        justifyContent: 'center'
    }
});

UserAvatar.propTypes = {
    userName: PropTypes.string.isRequired,
    userAvatarColor: PropTypes.string.isRequired,
    userAvatarUri: PropTypes.string,
    avatarPropsStyle: PropTypes.object
};

export {UserAvatar};
