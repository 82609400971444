import {apiRoot, mainUrl} from './apiSettings';

export const MURAL_QUERY = (date) => {
  return `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/mural/${date}`
};

export const GET_USER_BY_EMAIL = (email) => {
  return `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/user/${email}`
};

export const GET_DISPLAY_BY_EMAIL = (email) => {
  return `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/user/${email}/display`;
};

export const USER_UPDATE_AVATAR = `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/user/avatar`;

export const USER_GET_AVATAR = `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/user/avatar`;

export const TOKEN_GET_ALL = `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/token`;

/*  Quizz de Alternativas
    QUIZZ_GET_ALL - Pega todos os QuizzOpen que estão registrados na tabela.
 */
export const QUIZZ_GET_ALL = `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/quizzChoises/all`;
export const QUIZZ_OPEN_GET_ALL = `https://${apiRoot}.execute-api.sa-east-1.amazonaws.com/dev/quizzOpen/all`;

//GET - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/sponsor/logo/{uniqueId}
export const SPONSOR_GET_LOGO = (uniqueId) => `${mainUrl}/sponsor/logo/${uniqueId}`;
