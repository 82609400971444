import React from 'react';
import { View, FlatList } from 'react-native-web';
import {connect} from 'react-redux';
import {getPosts} from '../../redux/actions'

import PostComponent from './PostComponent';
import {InformationDialogue, Refresh} from '../../components';
import {getDayTimeFormatted} from "../../helpers/dateHelper";

class MuralListRender extends React.Component {

    state = {
        deleteInformationModalDescription: '',
        deleteInformationModalTitle: '',
        deleteInformationModalVisible: false,

        fetchDate: null,
    };

    onPostRemove = (success) => {

        if (success) {
            this.setState({
                deleteInformationModalTitle: 'Éxito',
                deleteInformationModalDescription: '¡El post se ha eliminado correctamente!'
            })
        } else {
            this.setState({
                deleteInformationModalTitle: 'Error',
                deleteInformationModalDescription: 'Se produjo un error al eliminar la publicación del muro. Vuelve a intentarlo más tarde o comunícate con el servicio de asistencia.'
            })
        }

        this.setState({
            deleteInformationModalVisible: true
        })

    };

    handleFetching = () => {
        if (this.props.isFetching) return;
        this.props.getPosts(this.props.authToken);
        this.setState({ fetchDate: getDayTimeFormatted() })
    };

    renderList() {
        if (this.props.isFetching === undefined) return null;
        if (this.props.isFetching) return null;

        return (
            <View style={{
                minWidth: '100%',
                minHeight: '100%',
            }}>
                <InformationDialogue
                    isModalVisible={this.state.deleteInformationModalVisible}
                    titleText={this.state.deleteInformationModalTitle}
                    descriptionText={this.state.deleteInformationModalDescription}
                    onClose={() => this.setState({ deleteInformationModalVisible: false })}
                />

                <FlatList
                    //contentContainerStyle={{ alignItems: 'center' }}
                    data={this.props.posts}
                    renderItem={({item}) => {
                        return <PostComponent
                            postData={{
                                userEmail: item.userEmail,
                                userName: item.userName,
                                userAvatarColor: item.userAvatarColor,
                                postDate: item.createdAt,
                                description: item.postText,
                                imageData: item.postImage,
                                muralId: item.uniqueId
                            }}
                            authToken={this.props.authToken}
                            onRemove={this.onPostRemove}
                        />
                    }}
                    keyExtractor={(item) => item.uniqueId.toString()}
                    refreshing={this.props.isFetching}
                    onRefresh={() => {
                        if (!this.props.isFetching)
                            this.props.getPosts(this.props.authToken);
                    }}
                />
            </View>
        )
    }

    componentWillMount() {
        this.handleFetching();
    }

    render() {
        return (
            <View>
                <Refresh
                    onRefreshClick={this.handleFetching}
                    isFetching={this.props.isFetching}
                    fetchDate={this.state.fetchDate}
                />

                {this.renderList()}
            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.post.isFetching,
        posts: state.post.posts,
        error: state.post.updateError,
        authToken: state.auth.authData.IdToken
    }
};

export default connect(mapStateToProps, { getPosts })(MuralListRender);
