import React from 'react';
import { View, Text, Image } from 'react-native-web';
import { connect } from 'react-redux';
import NavBar from "../../components/NavBar";
import {
	Card,
	ButtonWithSpinner,
	ConditionalTextRender,
	InformationDialogue,
	InputTextImage,
	CharCount,
} from "../../components";

import post from '../../api/methods/post';
import { addNews } from "../../api/models/newsModels";
import {NEWS_POST} from "./api/newsEndpoints";

import {
    cardContainer,
    cardSendButtonContainer,
    cardSendButtonText,
    cardErrorText,
    cardTitleStyle,
    cardInputContainerStyle,
    cardRowStyle,
    cardRowText
} from "../../constants/CardStyles";

//TODO(Celso): Add letter counter and max possible.

class NewsAddScreen extends React.Component {

    INPUT_TITLE = 'title';
    INPUT_SHORT_DESCRIPTION = 'shortDescription';
    INPUT_LONG_DESCRIPTION = 'longDescription';
    INPUT_EXTERNAL_LINK = 'externalLink';

    state = {
        title: '',
        shortDescription: '',
        longDescription: '',
        externalLink: '',

        file: null,
        image: null,
        imageStripped: null,

        uploading: false,

        inputError: '',
        success: '',
        error: '',

        newsAddSuccessModalVisible: false,
        newsAddErrorModalVisible: false,
    };

    resetState = () => {
        this.setState({
            title: '',
            shortDescription: '',
            longDescription: '',
            file: null,
            image: null,
            imageStripped: null,
        })
    };

    handleInput = (value, type) => {
        switch (type) {
            case this.INPUT_TITLE:
                this.setState({ title: value });
                break;

            case this.INPUT_SHORT_DESCRIPTION:
                this.setState({ shortDescription: value });
                break;

            case this.INPUT_LONG_DESCRIPTION:
                this.setState({ longDescription: value });
                break;

            case this.INPUT_EXTERNAL_LINK:
                this.setState({ externalLink: value });
                break;

            default:
                break;
        }
    };

    handleFileSelected = (e) => {
        if (this.state.uploading) return;

        const file = e.target.files[0];
        this.setState({ file: file });

        let reader = new FileReader();
        reader.onloadend = () => {

            //NOTE(Celso): From https://stackoverflow.com/questions/24289182/how-to-strip-type-from-javascript-filereader-base64-string
            let base64result = reader.result.split(',')[1];

            this.setState({
                image: reader.result,
                imageStripped: base64result
            })
        };

        reader.readAsDataURL(file);
    };

    handleSend = async () => {
        if (this.state.uploading) return;

        const { title, shortDescription, longDescription, externalLink, imageStripped } = this.state;

        //Input validation
        if (title === '' || shortDescription === '' || longDescription === '' || imageStripped === '') {
            this.setState({ inputError: 'Se debe completar el título, la descripción larga, la descripción breve y la imagen.' });
            return;
        }

        this.setState({ uploading: true, inputError: '', success: '', error: '' });

        const createdAt = new Date().toISOString();
        const dataModel = addNews(title, shortDescription, longDescription, externalLink, imageStripped, createdAt);

        try {

            //await post(NEWS_POST, dataModel, this.props.authToken);
            let response = await post(NEWS_POST, dataModel, this.props.authToken);
            let responseJson = await response.json();

            this.setState({
                success: '¡Noticias agregadas con éxito!',
                newsAddSuccessModalVisible: true,
            });
        } catch (e) {
            console.log('error', e);
            this.setState({
                error: `¡Error al agregar nuevas noticias! ${e}`,
                newsAddErrorModalVisible: false,
            });
        }

        this.setState({ uploading: false });
    };

    render() {
        return(
            <NavBar
                title={'Noticias - Agregar'}
                showBackButton={true}
            >
                <InformationDialogue
                    isModalVisible={this.state.newsAddSuccessModalVisible}
                    titleText={'Éxito'}
                    descriptionText={'¡La noticia fue agregada exitosamente!'}
                    buttons={[
                        {
                            text: 'Volver a la lista',
                            onPress: () => {
                                this.setState({ newsAddSuccessModalVisible: false, });
                                this.props.history.push('/news');
                            }
                        },
                        {
                            text: 'Agregar nueva noticia',
                            onPress: () => {
                                this.setState({
                                    newsAddSuccessModalVisible: false,
                                });
                                this.resetState();
                            }
                        }
                    ]}
                />

                <InformationDialogue
                    isModalVisible={this.state.newsAddErrorModalVisible}
                    titleText={'Error'}
                    descriptionText={'Error al agregar la noticia, comuníquese con el soporte.'}
                    onClose={() => this.setState({ newsAddErrorModalVisible: false })}
                />

                <Card containerProps={cardContainer}>

                    <Text style={cardTitleStyle}>
                        Agregando nueva noticia
                    </Text>

                    <InputTextImage
                        label={'Título: '}
                        onChangeText={(value) => this.handleInput(value, this.INPUT_TITLE)}
                        value={this.state.title}
                        placeholder={'Título'}
                        propsContainerStyle={cardInputContainerStyle}
                    />

                    <View>
	                    <InputTextImage
	                        label={'Descripción corta: '}
	                        onChangeText={(value) => this.handleInput(value, this.INPUT_SHORT_DESCRIPTION)}
	                        value={this.state.shortDescription}
	                        placeholder={''}
	                        propsContainerStyle={[cardInputContainerStyle, { height: 80, alignItems: 'flex-start' }]}
	                        propsInputStyle={{ height: 70 }}
	                        multiline={true}
	                        charCount={true}
	                        maxLength={280}
	                    />
	                </View>

                    <InputTextImage
                        label={'Descripción larga: '}
                        onChangeText={(value) => this.handleInput(value, this.INPUT_LONG_DESCRIPTION)}
                        value={this.state.longDescription}
                        placeholder={''}
                        propsContainerStyle={[cardInputContainerStyle, { height: 120, alignItems: 'flex-start' }]}
                        propsInputStyle={{ height: 110 }}
                        multiline={true}
                    />

                    <InputTextImage
                        label={'Link: '}
                        onChangeText={(value) => this.handleInput(value, this.INPUT_EXTERNAL_LINK)}
                        value={this.state.externalLink}
                        placeholder={''}
                        propsContainerStyle={cardInputContainerStyle}
                    />

                    <View style={cardRowStyle}>
                        <Text style={cardRowText}>Imagen: </Text>
                        <input type={"file"} onChange={this.handleFileSelected} />
                    </View>

                    {
                        this.state.image &&
                        <View style={[cardRowStyle, { flexDirection: 'column', alignItems: 'flex-start' }]}>
                            <Text style={cardRowText}>Preview: </Text>
                            <Image
                                source={this.state.image}
                                style={{ height: 100, width: 200 }}
                                resizeMode={'contain'}
                            />
                        </View>
                    }

                    <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <ConditionalTextRender
                            text={this.state.error}
                            textPropsStyle={cardErrorText}
                        />

                        <ConditionalTextRender
                            text={this.state.inputError}
                            textPropsStyle={cardErrorText}
                        />

                        <ButtonWithSpinner
                            isLoading={this.state.uploading}
                            text={'Enviar'}
                            onPress={this.handleSend}
                            containerPropsStyle={cardSendButtonContainer}
                            textPropsStyle={cardSendButtonText}
                        />
                    </View>
                </Card>
            </NavBar>
        )
    }
}

const mapStateToProps = state => {
    return {
        authToken: state.auth.authData.IdToken
    }
};

export default connect(mapStateToProps)(NewsAddScreen);
