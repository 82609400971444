import React from 'react';
import { View, Text } from 'react-native-web';
import FlatList from "react-native-web/dist/vendor/react-native/FlatList";
import { connect } from 'react-redux';

import get from '../../api/methods/get';
import { NEWS_GET} from "./api/newsEndpoints";

import { cardButtonContainer } from "../../constants/Styles";

import NavBar from "../../components/NavBar";
import { Button, SpinnerControl, InformationDialogue, AddButton, BoolTextRender } from "../../components";
import NewsListComponent from "./NewsListComponent";
import {getDayTimeFormatted} from "../../helpers/dateHelper";

class NewsScreen extends React.Component {

    state = {
        news: [],
        isFetching: false,
        fetchDate: null,
        fetchError: '',

        deleteSuccessModalVisible: false,
        deleteErrorModalVisible: false
    };

    handleDataFetching = async () => {

        if (this.state.isFetching) return;
        this.setState({ isFetching: true, fetchError: '' });

        try {
            console.log(NEWS_GET);
            let data = await get(NEWS_GET, this.props.authToken);
            let dataJson = await data.json();

            this.setState({
                news: dataJson.data.reverse(),
                fetchDate: getDayTimeFormatted()
            });
        } catch (e) {
            this.setState({ fetchError: 'Error al recuperar noticias.' });
        }

        this.setState({ isFetching: false });
    };

    onRemove = (success) => {
        this.handleDataFetching();

        if (success) {
            this.setState({ deleteSuccessModalVisible: true })
        } else {

        }
    };

    componentDidMount() {
        this.handleDataFetching();
    }

    render() {
        return(
            <NavBar title={'Noticias'}>
                <InformationDialogue
                    descriptionText={'¡La noticia fue eliminada exitosamente!'}
                    isModalVisible={this.state.deleteSuccessModalVisible}
                    onClose={() => this.setState({ deleteSuccessModalVisible: false })}
                    titleText={'Éxito'}
                />

                <InformationDialogue
                    descriptionText={'Se produjo un error al eliminar las noticias, ¡comuníquese con el soporte!'}
                    isModalVisible={this.state.deleteErrorModalVisible}
                    onClose={() => this.setState({ deleteErrorModalVisible: false })}
                    titleText={'Error'}
                />

                <BoolTextRender
                    text={'Todavía no tenemos ningún contenido agregado.'}
                    condition={() => {
                        if (this.state.isFetching) return false;
                        return this.state.news.length === 0;
                    }}
                />

                <View style={{ flexDirection: 'row', margin: 10 }}>
                    <Button
                        text={'Actualizar'}
                        onPress={this.handleDataFetching}
                        containerPropsStyle={cardButtonContainer}
                        textPropsStyle={{fontSize: 16}}
                    />

                    {
                        this.state.isFetching === true &&
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text> Actualizando </Text>
                            <SpinnerControl isFetching={this.state.isFetching} size={'small'} />
                        </View>
                    }

                    {
                        this.state.fetchDate !== null &&
                        <View style={{ margin: 10 }}>
                            <Text> { `Última actualización realizada en ${this.state.fetchDate}.` } </Text>
                        </View>
                    }

                </View>

                {
                    this.state.news.length !== 0 &&
                        <FlatList
                            data={this.state.news}
                            renderItem={({item}) => {
                                return (
                                    <NewsListComponent
                                        newsData={item}
                                        onRemove={this.onRemove}
                                        authToken={this.props.authToken}
                                    />)
                            }}
                            keyExtractor={(item) => item.uniqueId.toString()}
                        />
                }

                <AddButton
                    route={'/news/add'}
                    history={this.props.history}
                />

            </NavBar>
        )
    }
}

const mapStateToProps = state => {
    return {
        authToken: state.auth.authData.IdToken
    }
};

export default connect(mapStateToProps, null)(NewsScreen);
