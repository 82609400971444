import React from 'react';
import {
    View,
    StyleSheet
} from 'react-native-web';
import PropTypes from 'prop-types';

class Card extends React.Component {
    render() {
        const { children, containerProps } = this.props;

        return(
            <View style={[styles.cardContainer, containerProps]}>
                {children}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    cardContainer: {
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 8,
        shadowColor: 'black',
        shadowOffset: { width: 4, height: 4 },
        shadowOpacity: 0.4
    }
});

// Card.propTypes = {
//     containerProps: PropTypes.object
// };

//TODO(Celso): Change this to use { } export and add it to index file

export { Card };
