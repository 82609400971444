import React from 'react';
import PropTypes from 'prop-types';

import { ConditionalTextRender } from "./ConditionalTextRender";

const BoolTextRender = ({ text, condition, containerPropsStyle, textPropsStyle }) => {
    if (condition() === false) {
        return null;
    }
    return (
            <ConditionalTextRender
                text={text}
                containerPropsStyle={containerPropsStyle}
                textPropsStyle={textPropsStyle}
            />)
};

BoolTextRender.propTypes = {
    condition: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export {BoolTextRender};
