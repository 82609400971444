import Colors from "./Colors";

export const cardSendButtonContainer = {
    height: 30,
    margin: 10,
    width: 200,
    alignSelf: 'center'
};

export const cardSendButtonText = {
    fontSize: 16
};

export const cardErrorText = {
    color: 'red',
    fontSize: 16,
    textAlign: 'center'
};

export const cardContainer = {
    margin: 20,
    padding: 12
};

export const cardRowStyle = {
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 5,
    borderWidth: 0.4,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderRadius: 0,
    borderColor: Colors.borderColor
};

export const cardRowText = {
    color: Colors.inputFontColor,
    fontSize: 14,
    margin: 5,
};

export const cardInputContainerStyle = {
    // width: 500,
    padding: 8,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderRadius: 0,
    borderColor: Colors.mainColor,
};

export const cardTitleStyle = {
    fontSize: 26,
    textAlign: 'center',
    width: '100%'
};
