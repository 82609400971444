import React from 'react';
import {
    View,
    Text,
    StyleSheet
} from 'react-native';
import PropTypes from 'prop-types';

const ConditionalTextRender = ({text, containerPropsStyle, textPropsStyle}) => {
    if (text === undefined || text === null || text === '') return null;

    return (
        <View style={[styles.mainContainer, containerPropsStyle]}>
            <Text style={[styles.textStyle, textPropsStyle]}>
                {text}
            </Text>
        </View>
    )
};

const styles = StyleSheet.create({
    mainContainer: {
        width: '90%',
        margin: 10
    },
    textStyle: {
        fontSize: 18,
        textAlign: 'left'
    }
});

ConditionalTextRender.propTypes = {
    text: PropTypes.string.isRequired,
    containerPropsStyle: PropTypes.object,
    textPropsStyle: PropTypes.object
};

export {ConditionalTextRender};