import React from 'react';
import {
    View,
    Text,
    StyleSheet,
    Image
} from 'react-native';

import { GET_DISPLAY_BY_EMAIL } from '../../api/apiEndpoints';
import { ADMIN_MURAL_DELETE_POST } from '../../api/adminEndpoints';

import {Button, ConfirmationDialogue, Spinner, UserAvatar} from '../../components';
import Colors from '../../constants/Colors';
import deleteMethod from "../../api/methods/delete";
import { Card } from "../../components";
import {MURAL_DELETE} from "./api/muralEndpoints";

class PostComponent extends React.Component {

    state = {
        avatarUri: null,
        isDeleting: false,
        deleteError: '',
        deleteSuccess: false,

        deleteConfirmationModalVisible: false,
    };

    handleDelete = async () => {
        if (this.state.isDeleting) return;
        this.setState({ isDeleting: true });

        try {
            await deleteMethod(MURAL_DELETE(this.props.postData.muralId), this.props.authToken);
            this.props.onRemove(true);
            this.setState({
                isDeleting: false,
                deleteSuccess: true,
            });
        } catch (e) {
            console.log('erro ao deletar post do mural', e)
            this.props.onRemove(false);
        }

        this.setState({
            isDeleting: false,
        });
    };

    getInformation = async () => {
        fetch(GET_DISPLAY_BY_EMAIL(this.props.postData.userEmail), {
            method: 'GET',
            headers: {
                'Authorization': this.props.authToken
            }
        })
            .then(response => {
                if (response.status !== 200) throw (response);
                return response.json();
            })
            .then(responseJson => {
                if (responseJson.data.avatarUri !== undefined &&
                    responseJson.data.avatarUri !== '') {
                    this.setState({ avatarUri: responseJson.data.avatarUri })
                }
            })
            .catch(error => console.log(error));
    };

    componentDidMount() {
        this.getInformation();
    };

    render() {

        if (this.state.deleteSuccess) return null;

        if (this.state.isDeleting) {
            return(
                <View>
                    <Text style={{ textAlign: 'center' }}>
                        Eliminando post del mural...
                    </Text>
                    <Spinner />
                </View>
            )
        }

        const {userEmail, userName, userAvatarColor, postDate, description, imageData} = this.props.postData;

        let date = new Date(postDate);
        const timeDate = date.toLocaleDateString() + ' - ' + date.toLocaleTimeString();

        return (
            <Card containerProps={styles.mainContainerStyle}>
                <ConfirmationDialogue
                    isModalVisible={this.state.deleteConfirmationModalVisible}
                    onClose={() => this.setState({ deleteConfirmationModalVisible: false })}
                    onConfirm={() => this.handleDelete()}
                    descriptionText={'¿Estás seguro de que de quieres borrar este post del mural?'}
                />

                <View style={{
                    width: 64,
                    marginTop: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 10,
                }}>
                    <UserAvatar
                        userAvatarColor={userAvatarColor}
                        userAvatarUri={this.state.avatarUri}
                        userEmail={userEmail}
                        userName={userName}
                    />
                </View>

                <View style={{width: '70%' }}>
                    <View style={styles.userDetailsContainerStyle}>
                        <Text style={styles.userNameTextStyle}>
                            {userName}
                        </Text>
                        <Text style={styles.dateTextStyle}>
                            {' - ' + timeDate}
                        </Text>
                    </View>
                    <View style={styles.descriptionViewStyle}>

                        {
                            imageData != null &&
                            <Image
                                style={{ height: 280, margin: 8 }}
                                source={{ uri: imageData }}
                                resizeMode={'contain'}
                            />
                        }

                        <Text style={styles.descriptionTextStyle}>
                            {description}
                        </Text>
                    </View>
                </View>

                <View style={{
                    width: '30%'
                }}>
                    <Button
                        text={'Eliminar'}
                        onPress={() => this.setState({ deleteConfirmationModalVisible: true })}
                        containerPropsStyle={{
                            marginTop: 8,
                            height: 40,
                            alignSelf: 'center',
                            backgroundColor: 'red',
                            borderWidth: 0,
                            borderRadius: 4
                        }}
                        textPropsStyle={{
                            fontSize: 14,
                            marginLeft: 4,
                            marginRight: 4
                        }}
                    />
                </View>
            </Card>
        )
    }
}

const styles = StyleSheet.create({
    mainContainerStyle: {
        flexDirection: 'row',
        // width: 400,
        // borderWidth: 0.4,
        // borderTopWidth: 0,
        // borderLeftWidth: 0,
        // borderRightWidth: 0,
        margin: 10,
        borderColor: Colors.borderColor
    },
    userDetailsContainerStyle: {
        flexDirection: 'row',
        marginTop: 15,
        alignItems: 'center'
    },
    userNameTextStyle: {
        fontWeight: 'bold',
        fontSize: 14,
        flexWrap: 'wrap'
    },
    dateTextStyle: {
        fontStyle: 'italic',
        fontSize: 10,
        flexWrap: 'wrap',
    },
    descriptionViewStyle: {
        flexDirection: 'column',
        marginTop: 4,
        marginBottom: 12,
        flexWrap: 'wrap',
    },
    descriptionTextStyle: {
        fontSize: 14,
        textAlign: 'left',
        flexWrap: 'wrap',
        textAlignVertical: 'top',
    }
});

export default PostComponent;
