import React from 'react';
import { View, Text, StyleSheet, ProgressBar } from 'react-native-web';
import PropTypes from 'prop-types';
import Modal from 'modal-enhanced-react-native-web';
import {Button} from "..";

const ProgressDialogue = (props) => {

    const {
        isModalVisible,
        progressText,
        currentProgress,
        totalProgress,
        titleText,
        onClose,
    } = props;

    let progressNumber = currentProgress/totalProgress;

    let isFinished = Number(currentProgress) === Number(totalProgress);

    return (
        <Modal
            isVisible={isModalVisible}
            style={styles.modalContainer}
        >
            <View style={styles.backgroundContainer}>

                <Text style={styles.title}>
                    { titleText }
                </Text>

                {
                    isFinished === false &&
                        <View style={styles.backgroundContainer}>
                            <Text style={styles.progress}>
                                { progressText }
                            </Text>

                            <ProgressBar
                                style={{ borderRadius: 10, height: 10, width: '80%' }}
                                trackColor="#D1E3F6"
                                progress={progressNumber}
                            />
                        </View>
                }

                {
                    isFinished === true &&
                        <View>
                            <Text style={styles.progress}>
                                Processo concluído com sucesso!
                            </Text>

                            <Button
                                text={'Continuar'}
                                onPress={onClose}
                                containerPropsStyle={{ height: 40, margin: 10 }}
                                textPropsStyle={{ fontSize: 14 }}
                            />
                        </View>
                }

            </View>
        </Modal>
    )

};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backgroundContainer: {
        width: 400,
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 12,
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 22,
        marginBottom: 12
    },
    progress: {
        textAlign: 'center',
        marginBottom: 12,
    },
});

ProgressDialogue.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    progressText: PropTypes.string.isRequired,
    titleText: PropTypes.string.isRequired,
    currentProgress: PropTypes.number.isRequired,
    totalProgress: PropTypes.number.isRequired
};

export { ProgressDialogue };
