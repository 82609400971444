import React from 'react';
import {
    StyleSheet
} from 'react-native-web';
import PropTypes from 'prop-types';

import {Button} from "../";

const DialogueButtons = ({ buttons, onClose }) => {

    if (buttons === undefined || buttons === null || buttons.length === 0) {
        return (
            <Button
                text={'Continuar'}
                onPress={onClose}
                containerPropsStyle={styles.buttonContainer}
                textPropsStyle={styles.buttonText}
            />
        )
    }

    return (
        buttons.map(item => {
            return (
                <Button
                    key={item.text}
                    text={item.text}
                    onPress={item.onPress}
                    containerPropsStyle={styles.buttonContainer}
                    textPropsStyle={styles.buttonText}
                />
            )
        })
    )
};

const styles = StyleSheet.create({
    buttonContainer: {
        height: 40,
        margin: 10
    },
    buttonText: {
        fontSize: 14
    }
});

DialogueButtons.propTypes = {
    buttons: PropTypes.array,
    onClose: PropTypes.func
};

export default DialogueButtons;
