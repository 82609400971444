const get = async (endpoint, authToken, apiKey) => {
    try {
        let response = await fetch(endpoint, {
            method: 'GET',
            headers: authToken ?  {
                'Authorization': authToken,
            } : {
                'x-api-key': apiKey
            }
        });

        if (response.status !== 200) throw (response);

        return response;
    } catch (e) {
        throw e;
    }
};

export default get;
