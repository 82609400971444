import React from 'react';
import {Text, View} from 'react-native-web';
import {Bar, Pie} from "react-chartjs-2";

import NavBar from "../../components/NavBar";
import get from "../../api/methods/get";
import {API_KEY, GET_DATA, GET_DATA_AGGREGATION} from "./constants.";
import {SpinnerControl} from "../../components";
import Picker from "react-native-web/dist/exports/Picker";


class EverydayExcellenceGeneralGraph extends React.Component {

    state = {
        isFetching: false,
        currentPickerValue: "",

        currentData: null,
        aggregationData: null,
    }

    fetchMainData = async () => {
        if (this.state.isFetching) return;
        this.setState({ isFetching: true });

        try {

            let aggregationResult = await get(GET_DATA_AGGREGATION, null, API_KEY);
            let aggregationJson = await aggregationResult.json();

            let totalAggregation = aggregationJson.data.find(item => item.SK === "TOTAL");


            this.setState({
                aggregationData: aggregationJson.data,
                currentData: totalAggregation,
                currentPickerValue: "TOTAL"
            });

        } catch (e) {
            console.log(e);
        }

        this.setState({ isFetching: false });
    }

    renderData = () => {
        if (this.state.isFetching) return null;
        if (this.state.aggregationData === null) return null;

        const { currentData } = this.state;

        const { total, categoryOneTotal, categoryTwoTotal, categoryThreeTotal  } = currentData;
        const { myMoment, myMomentPerson, personagemAcaoInspiradora, pilulaInspiradora, cesarMarinho  } = currentData;
        const { gruposDeTrabalho, eventosExcelencia, desafiosExcelencia } = currentData;
        const { treinamentosPresenciais, facilitadores, treinamentosELearning } = currentData;

        return(
            <View style={{ padding: 12 }}>

                <Text style={{ fontWeight: 'bold', fontSize: 22, marginBottom: 12 }}>
                    Datos
                </Text>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text>
                        Seleccione una fecha para ver:
                    </Text>
                    <Picker
                        value={this.state.currentPickerValue}
                        onValueChange={(newValue) => {

                            const newDataset = this.state.aggregationData.find(item => item.SK === newValue);
                            this.setState({
                                currentPickerValue: newValue,
                                currentData: newDataset,
                            });
                        }}
                    >
                        {
                            this.state.aggregationData.map((item) => {
                                return (
                                    <Picker.Item key={item.SK} label={item.SK} value={item.SK} />
                                )
                            })
                        }
                    </Picker>
                </View>

                <Text style={{ fontSize: 18, marginBottom: 12 }}>
                    Entradas totales
                </Text>
                <View style={{ width: 600, height: 200 }}>

                    <Bar
                        data={{
                            labels: ["Categoría 1", "Categoría 2", "Categoría 3", "Total"],
                            datasets: [{
                                label: "Entradas",
                                backgroundColor: 'rgba(99,138,255,0.5)',
                                borderColor: 'rgb(26,80,250)',
                                borderWidth: 1,
                                hoverBackgroundColor: 'rgba(99,138,255,0.5)',
                                hoverBorderColor: 'rgb(255,251,120)',
                                data: [categoryOneTotal, categoryTwoTotal, categoryThreeTotal, total]
                            }]

                        }}
                        width={600}
                        height={200}
                        options={{
                            maintainAspectRatio: false
                        }}
                    />
                </View>

                <Text style={{ fontSize: 18, marginBottom: 12 }}>
                     Entradas de categoría totales: “Soy reconocido por trabajar con excelencia”
                </Text>
                <View style={{ width: 600, height: 200}}>
                    <Pie
                        data={{
                            labels: ["My Moment", "My Moment Person", "Dosis de Inspiración", "Town Hall", "Personaje de Acción Inspiradora"],
                            datasets: [{
                                label: "Soy reconocido por trabajar con excelencia",
                                data: [myMoment, myMomentPerson, pilulaInspiradora, cesarMarinho, personagemAcaoInspiradora],
                                backgroundColor: [
                                    '#FF6384',
                                    '#36A2EB',
                                    '#FFCE56',
                                    '#56ff67',
                                    '#be56ff'
                                ],
                            }]
                        }}
                        width={160}
                        height={160}
                        options={{
                            maintainAspectRatio: false
                        }}
                    />
                </View>

                <Text style={{ fontSize: 18, marginBottom: 12 }}>
                    Entradas de categoría totales: “Yo participo de acciones de la campaña"
                </Text>
                <View style={{ width: 600, height: 200, flexDirection: 'row' }}>
                    <Pie
                        data={{
                            labels: ["Grupos de trabajo", "Eventos Everyday Excellence", "Desafíos de Excelencia"],
                            datasets: [{
                                label: "Yo participo de acciones de la campaña",
                                data: [gruposDeTrabalho, eventosExcelencia, desafiosExcelencia],
                                backgroundColor: [
                                    '#FF6384',
                                    '#36A2EB',
                                    '#FFCE56',
                                ],
                            }]
                        }}
                        width={160}
                        height={160}
                        options={{
                            maintainAspectRatio: false
                        }}
                    />
                </View>

                <Text style={{ fontSize: 18, marginBottom: 12 }}>
                    Entradas de categoría totales: “Yo me capacito para actuar con excelencia”
                </Text>
                <View style={{ width: 600, height: 200, flexDirection: 'row' }}>
                    <Pie
                        data={{
                            labels: ["Entrenamiento Presencial", "Entrenamiento eLearning", "Facilitadores Service Excellence"],
                            datasets: [{
                                label: "Yo me capacito para actuar con excelencia",
                                data: [treinamentosPresenciais, treinamentosELearning, facilitadores],
                                backgroundColor: [
                                    '#FF6384',
                                    '#36A2EB',
                                    '#FFCE56',
                                ],
                            }]
                        }}
                        width={160}
                        height={160}
                        options={{
                            maintainAspectRatio: false
                        }}
                    />
                </View>

            </View>
        )

    }


    componentDidMount() {
        this.fetchMainData();
    }

    render() {

        return (
            <NavBar title={"Datos"} showBackButton={true}>

                <SpinnerControl isFetching={this.state.isFetching} text={"Cargando datos!"} />

                {this.renderData()}

            </NavBar>
        )
    }

}

export default EverydayExcellenceGeneralGraph;
