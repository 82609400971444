import React from 'react';
import { View, Text } from 'react-native-web';
import { withRouter } from 'react-router-dom';
import NavBar from "../components/NavBar";

class HomeScreen extends React.Component {

    render() {
        return (
            <View style={{ flex: 1 }}>
                <NavBar title={'Home'}>

                </NavBar>
            </View>
        )
    }
}

export default withRouter(HomeScreen);

