export function addNews(title, shortDescription, longDescription, externalLink, image, createdAt) {
    return {
        'title': title,
        'shortDescription': shortDescription,
        'longDescription': longDescription,
        'externalLink': externalLink,
        'createdAt': createdAt,
        'newsImage': image,
    }
}

export function editNews(uniqueId, title, shortDescription, longDescription, externalLink, image, haveNewImage) {
    return {
        'uniqueId': uniqueId,
        'title': title,
        'shortDescription': shortDescription,
        'longDescription': longDescription,
        'externalLink': externalLink,
        'newsImage': image,
        'haveNewImage': haveNewImage
    }
}

export function deleteNews(uniqueId) {
    return {
        'uniqueId': uniqueId
    }
}