import React from 'react';
import {
    Text,
    TouchableOpacity,
    StyleSheet
} from 'react-native';
import PropTypes from 'prop-types';

import Colors from '../../constants/Colors';

const Button = ({
                    text,
                    onPress,
                    textPropsStyle,
                    containerPropsStyle,
                    disabled
                }) => {
    return (

        <TouchableOpacity
            disabled={disabled === undefined ? false : disabled}
            onPress={onPress}
            style={[styles.containerStyle, containerPropsStyle]}
        >
            <Text style={[styles.textStyle, textPropsStyle]}>
                {text}
            </Text>
        </TouchableOpacity>
    )
};

const styles = StyleSheet.create({
    containerStyle: {
        padding: 12,
        justifyContent: 'center',
        height: 60,
        alignSelf: 'stretch',
        backgroundColor: Colors.mainColor,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.mainColor,
    },
    textStyle: {
        color: '#FFF',
        fontSize: 28,
        alignSelf: 'center',
    }
});

Button.propTypes = {
    text: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export {Button};
