//TODO(Celso): Change file name to dateHelper.

import moment from 'moment';
import 'moment/locale/pt-br';

export const getMomentDateFromISOFormatted = (isoDate) => {
    moment.locale('pt-br');
    return moment(isoDate).format("dddd, MMMM Do YYYY, H:mm:ss a");
};

export const getDayTimeFormatted = () => {
    const date = new Date();
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
};
