import React from 'react';
import {
  View
} from 'react-native';

import MuralListRender from './MuralListRender';
import NavBar from "../../components/NavBar";

class MuralScreen extends React.Component {

  render() {
    return (
      <View style={{ flex: 1 }}>
        <NavBar title={"Mural"}>
            <MuralListRender />
        </NavBar>
      </View>
    )
  }
}

export default MuralScreen;