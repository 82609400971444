import React, { useState } from "react";
import {
	useTable,
	usePagination,
	useFilters,
	useGlobalFilter
} from "react-table";
import Colors from "../../constants/Colors";
import Pagination from "./Pagination";
import { InputTextImage } from "../input/InputTextImage";
import { stringIsEmpty } from "../../helpers/stringCheker";
import { Card } from "../card/Card";
import { Text } from "react-native-web";
import { Spacing } from "../Spacing";

const Table = ({
	columns,
	data,
	havePagination,
	filterSettings = {
		haveFilter: false,
		filterKey: null,
		useGlobalFilter: true,
		placeholder: null
	}
}) => {
	const [filterInput, setFilterInput] = useState("");

	const tableInstance = useTable(
		{
			columns: columns,
			data: data,
			initialState: {
				pageIndex: 0
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,

		setFilter,
		setGlobalFilter,

		// For pagination
		state: { pageIndex, pageSize }
	} = tableInstance;

	return (
		<>
			{filterSettings.haveFilter && (
				<Card containerProps={{ marginBottom: 12 }}>
					<Text>Filtro: </Text>
					<Spacing height={12} />
					<InputTextImage
						value={filterInput}
						placeholder={
							stringIsEmpty(filterSettings.placeholder)
								? 'filtro'
								: filterSettings.placeholder
						}
						onChangeText={e => {
							const value = e || undefined;

							setFilterInput(value);

							if (filterSettings.useGlobalFilter) {
								setGlobalFilter(value);
							} else {
								setFilter(filterSettings.filterKey, value);
							}
						}}
						propsContainerStyle={{ borderRadius: 0 }}
					/>
				</Card>
			)}

			<table
				{...getTableProps}
				style={{
					"borderSpacing": 0,
					border: "1px solid black"
				}}
			>
				<thead
					style={{
						backgroundColor: Colors.gridHeader,
						color: "white",
						fontWeight: "bold",
						border: "1px solid black",
						fontSize: 22
					}}
				>
					{// Loop over the header rows
					headerGroups.map(headerGroup => (
						// Apply the header row props
						<tr>
							{// Loop over the headers in each row
							headerGroup.headers.map(column => (
								// Apply the header cell props
								<th>
									{// Render the header
									column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>

				{/* Apply the table body props */}
				<tbody style={{ border: "1px solid black" }}>
					{// Loop over the table rows
					page.map((row, i) => {
						// Prepare the row for display
						prepareRow(row);
						return (
							// Apply the row props
							<tr
								style={{
									border: "1px solid black",
									alignItems: "center"
								}}
							>
								{// Loop over the rows cells
								row.cells.map(cell => {
									// Apply the cell props

									return (
										<td
											style={{
												fontSize: 16,
												"borderRight": "1px solid black",
												"borderBottom": "1px solid black",
												paddingBottom: 6,
												paddingTop: 6,
												backgroundColor: Colors.gridCell
											}}
										>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>

			{havePagination === true && (
				<Pagination
					{...tableInstance}
					pageIndex={pageIndex}
					pageSize={pageSize}
				/>
			)}
		</>
	);
};

export default Table;
