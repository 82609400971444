import {mainUrl, guruId} from "../../../api/apiSettings";

// ADMIN

//NOTE(Celso): Pointing to i9-hub api

// GET - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/schedule/{guruId}/admin
export const SCHEDULE_GET_ALL = `${mainUrl}/schedule/${guruId}/admin`;

// POST - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/schedule/{guruId}/admin
export const SCHEDULE_ADD_ACTIVITY = `${mainUrl}/schedule/${guruId}/admin`;

// PATCH - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/schedule/{guruId}/admin
export const SCHEDULE_EDIT_ACTIVITY = `${mainUrl}/schedule/${guruId}/admin`;

// GET - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/schedule/{guruId}/admin/days
// POST - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/schedule/{guruId}/admin/days
export const SCHEDULE_GET_DAYS = `${mainUrl}/schedule/${guruId}/admin/days`;

// DELETE - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/schedule/{guruId}/admin/{uniqueId}
export const SCHEDULE_DELETE_ACTIVITY = (uniqueId) => `${mainUrl}/schedule/${guruId}/admin/${uniqueId}`;

// GET - https://fl31oicneg.execute-api.sa-east-1.amazonaws.com/dev/schedule/{guruId}/admin/comments/{activityId}
export const SCHEDULE_GET_COMMENTS = (activityId) => `${mainUrl}/schedule/${guruId}/admin/comments/${activityId}`;
