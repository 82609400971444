import React from 'react';
import {
    TextInput,
    View,
    Text,
    Image
} from 'react-native';
import PropTypes from 'prop-types';

import Colors from '../../constants/Colors';
import { CharCount } from '../../components';

const InputTextImage = ({
                            label,
                            value,
                            onChangeText,
                            placeholder,
                            secureTextEntry,
                            keyboardType,
                            propsContainerStyle,
                            propsImageStyle,
                            propsInputStyle,
                            onFocusCallback,
                            onBlurCallback,
                            autoCapitalize,
                            imageSource,
                            charCount,
                            type, multiline, editable,
                            maxLength
                        }) => {

    const {
        inputStyle,
        labelStyle,
        containerStyle,
        imageStyle
    } = styles;

    // if (imageSource === undefined) {
    //     imageSource = require('../../img/pointers/bluePointer.png')
    // }

    return (
        <View style={[containerStyle, propsContainerStyle]}>
            {
                imageSource !== undefined &&
                <Image
                    style={[imageStyle, propsImageStyle]}
                    source={imageSource}
                />
            }

            {
                label !== undefined &&
                    <Text style={labelStyle}>
                        {label}
                    </Text>
            }
            <View style={[{ flex: 2, flexDirection: 'column' }, propsInputStyle]}>
	            <TextInput
	                multiline={multiline}
	                keyboardType={keyboardType}
	                secureTextEntry={secureTextEntry}
	                placeholder={placeholder}
	                autoCorrect={false}
	                autoCapitalize={autoCapitalize}
	                style={[inputStyle, propsInputStyle]}
	                value={value}
	                onChangeText={onChangeText}
	                onFocus={onFocusCallback}
	                onBlur={onBlurCallback}
	                type={type}
	                editable={editable}
	                maxLength={maxLength}
	            />
	            {
	            	charCount == true &&
	            		<CharCount
		            		text={value}
		            		outOf={maxLength}
		            		style={{ color: Colors.inputFontColor, marginLeft: '82%' }}
	            		/>
	            }
            </View>
        </View>
    );
};

const styles = {
    containerStyle: {
        height: 50,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 5,
        borderWidth: 1,
        borderRadius: 40,
        borderColor: Colors.inputLineColor,
        padding: 2
    },
    inputStyle: {
        color: Colors.inputFontColor,
        paddingRight: 5,
        paddingLeft: 5,
        fontSize: 14,
    },
    labelStyle: {
        color: Colors.inputFontColor,
        fontSize: 14,
        margin: 5
    },
    imageStyle: {
        width: 25,
        height: 25,
        margin: 5
    }
};

InputTextImage.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    charCount: PropTypes.bool,
    imageSource: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChangeText: PropTypes.func.isRequired,
    // propsContainerStyle: PropTypes.object,
    // propsImageStyle: PropTypes.object,
};

export { InputTextImage };
