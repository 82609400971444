import React from 'react';
import {
    View,
    Text
} from 'react-native';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import Colors from "../constants/Colors";
import Drawer from './drawer/Drawer';
import {ButtonImage} from "./button/ButtonImage";

class NavBar extends React.Component {

    render() {

        return(
            <View style={{ backgroundColor: Colors.backgroundColor,  }}>
                <View
                    style={{
                        backgroundColor: Colors.navBarColor,
                        alignSelf: 'stretch',
                        height: 50,
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}
                >
                    {
                        this.props.showBackButton !== undefined &&
                            <ButtonImage
                                onPress={() => this.props.history.goBack()}
                                imageSource={require('../img/arrowIconLeft.png')}
                                imagePropsStyle={{ width: 32, height: 32 }}

                            />
                    }

                    <Text style={{
                        marginLeft: 20,
                        color: 'white',
                        fontSize: 26,
                        fontWeight: 'bold'
                    }}>
                        {this.props.title}
                    </Text>
                </View>

                <View style={{
                    flexDirection: 'row'
                }}>
                    <Drawer />
                    <View style={{ flex: 1, backgroundColor: Colors.backgroundColor, maxWidth: 800 }}>
                        {this.props.children}
                    </View>
                </View>
            </View>
        )
    }
}

NavBar.propTypes = {
    title: PropTypes.string,
    showBackButton: PropTypes.bool
};

export default withRouter(NavBar);
