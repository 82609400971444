import React from 'react';
import {
    View,
    StyleSheet
} from 'react-native-web';
import PropTypes from 'prop-types';

const Card = (props) => {

    const { children, containerProps } = props;

    return(
        <View style={[styles.cardContainer, containerProps]}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    cardContainer: {
        backgroundColor: 'white',
        padding: 12,
        borderRadius: 8,
        shadowColor: 'black',
        shadowOffset: { width: 4, height: 4 },
        shadowOpacity: 0.4
    }
});

Card.propTypes = {
    containerProps: PropTypes.any
};

export { Card };
