const post = async (endpoint, body, authToken) => {
    try {
        let response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });

        if (response.status !== 200) throw (response);

        return response;

    } catch (e) {
        throw e;
    }
};

export default post;
