import React from 'react';
import {View, Text, Dimensions, CheckBox, ScrollView} from 'react-native-web';
import { connect } from 'react-redux';

import NavBar from "../../components/NavBar";
import get from "../../api/methods/get";
import {Button, ButtonWithSpinner, SpinnerControl} from "../../components";

import Grid from "./Grid";
import {API_KEY, DOWNLOAD_DATA, GET_DATA, GET_DATA_AGGREGATION} from "./constants.";
import EverydayTable from "./EverydayTable";
import {Spacing} from "../../components/Spacing";
//API Key
//dpu2RHoCjO3o8qG5DcZJU6LxsUIiVKNDaVMMr20f
//x-api-key

// categoryOneAmount: 4
// categoryThreeAmount: 0
// totalPointsAfterMedia: 15.333333333333334
// categoryOnePointsAfterMedia: 15.333333333333334
// categoryTwoPointsAfterMedia: 0
// categoryThreePointsAfterMedia: 0
// categoryTwoAmount: 0
// categoryOnePoints: 46
// categoryTwoPoints: 0
// categoryThreePoints: 0
// dataType: "aggregation"
// declineAdmissionTime: null
// declineContribution: "Usuário está marcado como Gestor!"
// havePointsOnAllCategory: false
// media: 15.333333333333334
// passAdmissionTime: true
// passContribution: false
// totalPointsBeforeMedia: 46
// userId: "V826022"

class EverydayExcellenceScreen extends React.Component {


    state = {
        isFetching: false,
        rawData: null,

        dataToRender: null,

        showAllUsers: false,

    }

    fetchMainData = async () => {
        if (this.state.isFetching) return;
        this.setState({ isFetching: true });

        try {
            let result = await get(GET_DATA, null, API_KEY);
            let resultJson = await result.json();

            this.setState({
                rawData: resultJson.data,
            }, () => this.treatData());

        } catch (e) {
            console.log(e);
        }

        this.setState({ isFetching: false });
    }

    treatData = () => {

        const filterData = this.state.rawData.filter(item => {
            console.log(item);
            if (this.state.showAllUsers) return item;

            if ( item.categoryOnePointsAfterMedia > 0 && item.categoryTwoPointsAfterMedia > 0 && item.categoryThreePointsAfterMedia > 0 && item.passAdmissionTime && item.passContribution) {
                return item;
            }
        })

        const treatedData = filterData.map(item => {
            return {
                vendorId: item.userId,
                userEmail: item.emailFromExternalData,
                categoryOnePoints: item.categoryOnePointsAfterMedia.toFixed(2),
                categoryTwoPoints: item.categoryTwoPointsAfterMedia.toFixed(2),
                categoryThreePoints: item.categoryThreePointsAfterMedia.toFixed(2),
                totalPoints: item.totalPointsAfterMedia.toFixed(2),
                clickable: true,
            }
        })

        treatedData.sort((a, b) => b.totalPoints - a.totalPoints);

        this.setState({
            dataToRender: treatedData,
        });
    }

    componentDidMount() {
        this.fetchMainData();
    }

    renderGrid = () => {
        if (this.state.rawData === null) return null;
        if (this.state.dataToRender === null) return null;

        let { width } = Dimensions.get('window');
        width = Math.min(width, 1400);
        const gridSize = (width - 180) * 0.8;

        const headerRender = [ "Vendor ID", "Email", "Categoria 1", "Categoria 2", "Categoria 3", "Total", "Detalhes" ]
        const columnSize = gridSize / headerRender.length;

        return (
            <View style={{ padding: 12 }}>

                <Text style={{ fontWeight: 'bold', fontSize: 22, marginBottom: 12 }}>
                    Ranking
                </Text>

                <View style={{ flexDirection: 'row', marginBottom: 12, alignItems: 'center' }}>
                    <Text style={{ marginRight: 8 }}>
                        Mostrar todos usuários:
                    </Text>
                    <CheckBox
                        onValueChange={newValue => this.setState({ showAllUsers: newValue }, this.treatData)}
                        value={this.state.showAllUsers}
                    />
                </View>

                {
                    this.state.dataToRender.length > 0 &&
                    <EverydayTable data={this.state.dataToRender} history={this.props.history} />
                }
            </View>

        )
    }

    render() {
        return (
            <NavBar title={"Everyday Excellence"}>
                <ScrollView>
                    {
                        this.state.isFetching === false &&
                        <View style={{ padding: 12 }}>
                            <Text style={{ fontWeight: 'bold', fontSize: 22, marginBottom: 12 }}>
                                Dados
                            </Text>

                            <Button
                                text={'Ir al detalle de los datos'}
                                onPress={() => { this.props.history.push(`/everydayexcellence/data`) }}
                                containerPropsStyle={{ height: 40, width: 300 }}
                                textPropsStyle={{ fontSize: 16 }}
                            />

                            <Spacing height={12} />

                            <ButtonWithSpinner
                                isLoading={this.state.requestingDownload}
                                text={'Solicitar descarga de datos'}
                                onPress={async () => {
                                    this.setState({ requestingDownload: true });
                                    try {
                                        let result = await get(DOWNLOAD_DATA, null, API_KEY);
                                        let resultJson = await result.json();
                                        window.open(resultJson.data);
                                    } catch (e) {
                                        console.log(e);
                                    }
                                    this.setState({ requestingDownload: false });
                                }}
                                containerPropsStyle={{ height: 40, width: 300 }}
                                textPropsStyle={{ fontSize: 16 }}
                            />
                        </View>
                }

                    <SpinnerControl isFetching={this.state.isFetching} text={"Carregando dados!"} />

                    { this.renderGrid() }
                </ScrollView>
            </NavBar>
        )
    }

}

const mapStateToProps = state => {
    return {
        authToken: state.auth.authData.IdToken
    }
}

export default connect(mapStateToProps, {})(EverydayExcellenceScreen);
