import React from 'react';
import { Text } from 'react-native-web';
import PropTypes from 'prop-types';

import Colors from '../../constants/Colors';

const CharCount = (props) => {
	
	let text = props.text;
	if (text === undefined || text === null) { text = ''}

	const outOf = props.outOf ? '/' + props.outOf : '';

	return(
		<Text style={props.style}>

			{ 'Characters: ' + text.length + outOf}
		
		</Text>
	);
};

CharCount.propTypes = {
	text: PropTypes.string.isRequired,
	style: PropTypes.object,
	outOf: PropTypes.number,
}

export { CharCount };