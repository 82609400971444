import React from 'react';
import { View, Text } from 'react-native-web';
import queryString from 'query-string';
import { Pie, HorizontalBar, Bar } from 'react-chartjs-2';

import NavBar from "../../components/NavBar";
import get from "../../api/methods/get";
import {API_KEY, GET_MONTH_DATA} from "./constants.";
import {Bold, Button, Card, SpinnerControl, Title} from "../../components";


class EverydayExcellenceDetail extends React.Component {

    state = {
        monthData: [],
        accessData: null,
        aggregationData: null,

        isFetching: false,
        vendorId: null
    }

    handleFetching = async () => {
        if (this.state.isFetching) return;
        this.setState({ isFetching: true });

        try {

            let getResult = await get(GET_MONTH_DATA(this.state.vendorId), null, API_KEY);
            let resultJson = await getResult.json();

            const aggregationData = resultJson.data.find(item => item.dataType === "aggregation");
            const accessData = resultJson.data.find(item => item.dataType === "access");

            let monthData = [];

            for (let data of resultJson.data) {
                if (data.dataType === "profile" || data.dataType === "access" || data.dataType === "aggregation")
                    continue;

                monthData.push(data);
            }

            this.setState({
                monthData: monthData,
                aggregationData: aggregationData,
                accessData: accessData
            })

        } catch (e) {
            console.log(e);
        }

        this.setState({ isFetching: false });
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.setState({ vendorId: values.vendorId }, this.handleFetching)
    }

    render() {

        return (
            <NavBar title={`Usuario - ${this.state.vendorId}`} showBackButton={true}>

                <SpinnerControl isFetching={this.state.isFetching} text={"Cargando datos del usuario."} />

                <View style={{ padding: 22 }}>
                    {
                        this.state.accessData !== null &&
                        <Card containerProps={{ marginBottom: 20 }}>
                            <Title title={'Datos del usuario'} propsStyle={{ marginBottom: 12 }} />
                            <Bold label={'Vendor ID:'} description={this.state.vendorId} />
                            <Bold label={'Contribuyente:'} description={this.state.accessData.contribuinte} />
                            <Bold label={'Email:'} description={this.state.accessData.emailFromExternalData} />
                            <Bold label={'Fecha de Admisíon:'} description={this.state.accessData.hiredDate} />
                        </Card>
                    }

                    {
                        this.state.aggregationData !== null &&
                        <Card containerProps={{ marginBottom: 20 }}>
                            <Title title={'Puntuación total'} propsStyle={{ marginBottom: 12 }} />

                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ width: '50%' }}>
                                    <Bold label={'Antes de aplicar el promedio:'} />
                                    <Bold label={'Categoría 1:'} description={`${this.state.aggregationData.categoryOnePoints} pontos`} />
                                    <Bold label={'Categoría 2:'} description={`${this.state.aggregationData.categoryTwoPoints} pontos`} />
                                    <Bold label={'Categoría 3:'} description={`${this.state.aggregationData.categoryThreePoints} pontos`} />
                                    <div style={{ height: 22 }} />
                                    <Bold label={'Calculando promedio:'} />
                                    <Bold label={'Promedio:'} description={`(${this.state.aggregationData.categoryOnePoints} + ${this.state.aggregationData.categoryTwoPoints} + ${this.state.aggregationData.categoryThreePoints}) / 3 = ${this.state.aggregationData.media}`} />
                                    <div style={{ height: 22 }} />
                                    <Bold label={'Después de aplicar el promedio:'} />
                                    <Bold label={'Categoría 1:'} description={`${this.state.aggregationData.categoryOnePointsAfterMedia} pontos`} />
                                    <Bold label={'Categoría 2:'} description={`${this.state.aggregationData.categoryTwoPointsAfterMedia} pontos`} />
                                    <Bold label={'Categoría 3:'} description={`${this.state.aggregationData.categoryThreePointsAfterMedia} pontos`} />
                                    <Bold label={'Total de puntos:'} description={`${this.state.aggregationData.totalPointsAfterMedia} pontos`} />
                                </View>

                                <View>
                                    <Title title={`Puntuación`} propsStyle={{ marginBottom: 12 }} />
                                    <HorizontalBar
                                        data={{
                                            labels: ["Categoría 1", "Categoría 2", "Categoría 3", "Total"],
                                            datasets: [{
                                                label: "Puntuación",
                                                backgroundColor: 'rgba(99,138,255,0.5)',
                                                borderColor: 'rgb(26,80,250)',
                                                borderWidth: 1,
                                                hoverBackgroundColor: 'rgba(99,138,255,0.5)',
                                                hoverBorderColor: 'rgb(255,251,120)',
                                                data: [this.state.aggregationData.categoryOnePointsAfterMedia, this.state.aggregationData.categoryTwoPointsAfterMedia, this.state.aggregationData.categoryThreePointsAfterMedia, this.state.aggregationData.totalPointsAfterMedia]
                                            }]
                                        }}
                                    />
                                </View>

                            </View>




                        </Card>
                    }

                    {
                        this.state.monthData.map(item => {
                            return (
                                <Card containerProps={{ marginBottom: 20}} key={item.dataType} >
                                    <Title title={`Detalles - ${item.dataType}`} propsStyle={{ marginBottom: 12 }} />
                                    <View style={{ flex: 1, flexDirection: 'row' }}>
                                        <View style={{ width: '50%' }}>

                                            <Bold label={'Categoría 1:'} description={`${item.categoryOneResult} pontos`} />
                                            <Bold label={'Categoría 1:'} description={`${item.categoryOneAmount} entradas`} />
                                            <div style={{ height: 22 }} />
                                            <Bold label={'Categoría 2:'} description={`${item.categoryTwoResult} pontos`} />
                                            <Bold label={'Categoría 2:'} description={`${item.categoryTwoAmount} entradas`} />
                                            <div style={{ height: 22 }} />
                                            <Bold label={'Categoría 3:'} description={`${item.categoryThreeResult} pontos`} />
                                            <Bold label={'Categoría 3:'} description={`${item.categoryThreeAmount} entradas`} />
                                            <div style={{ height: 32 }} />
                                            <Button
                                                text={"Detalles"}
                                                onPress={() => {
                                                    this.props.history.push(`/everydayexcellence/month/details?vendorId=${this.state.vendorId}&date=${item.dataType}`)
                                                }}
                                                containerPropsStyle={{ height: 20, width: 200 }}
                                                textPropsStyle={{ fontSize: 16 }}
                                            />
                                        </View>
                                        <View style={{ flex: 1, alignItems: 'center' }}>
                                            <Title title={`Total de Entradas`} propsStyle={{ marginBottom: 12 }} />
                                            <Pie
                                                data={{
                                                    labels: [ 'Categoría 1', "Categoría 2", "Categoría 3" ],
                                                    datasets: [{
                                                        label: item.dataType.toString(),
                                                        data: [item.categoryOneAmount, item.categoryTwoAmount, item.categoryThreeAmount],
                                                        backgroundColor: [ '#FF6384', '#36A2EB', '#FFCE56' ],
                                                        hoverBackgroundColor: [ '#FF6384', '#36A2EB', '#FFCE56' ]
                                                    }]
                                                }}
                                            />

                                        </View>
                                    </View>

                                </Card>
                            )
                        })
                    }

                </View>

            </NavBar>
        )
    }
}

// categoryOneAmount: 4
// categoryThreeAmount: 0
// totalPointsAfterMedia: 15.333333333333334
// categoryOnePointsAfterMedia: 15.333333333333334
// categoryTwoPointsAfterMedia: 0
// categoryThreePointsAfterMedia: 0
// categoryTwoAmount: 0
// categoryOnePoints: 46
// categoryTwoPoints: 0
// categoryThreePoints: 0
// dataType: "aggregation"
// declineAdmissionTime: null
// declineContribution: "Usuário está marcado como Gestor!"
// havePointsOnAllCategory: false
// media: 15.333333333333334
// passAdmissionTime: true
// passContribution: false
// totalPointsBeforeMedia: 46
// userId: "V826022"


export default EverydayExcellenceDetail;
