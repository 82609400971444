import {
    SCHEDULE_DAYS_GET,
    SCHEDULE_DAYS_GET_FAILED,
    SCHEDULE_DAYS_GET_SUCCESS,

    // SCHEDULE_DAYS_UPDATE,
    // SCHEDULE_DAYS_UPDATE_FAILED,
    // SCHEDULE_DAYS_UPDATE_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
    daysData: [],
    isGettingDays: false,

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SCHEDULE_DAYS_GET:
            return { ...state, isGettingDays: true };

        case SCHEDULE_DAYS_GET_FAILED:
            return { ...state, isGettingDays: false, daysData: [] };

        case SCHEDULE_DAYS_GET_SUCCESS:
            return { ...state, isGettingDays: false, daysData: action.payload };

        default:
            return state;
    }
}
