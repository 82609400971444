export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAILED = 'login_user_failed';
export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';

export const SCHEDULE_DAYS_UPDATE = 'schedule_days_update';
export const SCHEDULE_DAYS_UPDATE_SUCCESS = 'schedule_days_update_success';
export const SCHEDULE_DAYS_UPDATE_FAILED = 'schedule_days_update_failed';

export const SCHEDULE_DAYS_GET = 'schedule_days_get';
export const SCHEDULE_DAYS_GET_SUCCESS = 'schedule_days_get_success';
export const SCHEDULE_DAYS_GET_FAILED = 'schedule_days_get_failed';

//TODO(Celso): Pretty sure we can remove this from redux
export const POST_UPDATE_POSTS = 'post_update_posts';
export const POST_UPDATE_POSTS_FAIL = 'post_update_posts_fail';
export const POST_UPDATE_POSTS_SUCCESS = 'post_update_posts_success';

