const deleteMethod = async (endpoint, authToken) => {
    try {
        let response = await fetch(endpoint, {
            method: 'DELETE',
            headers: {
                'Authorization': authToken,
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== 200) throw (response);
        return response;
    } catch (e) {
        throw e;
    }
};

export default deleteMethod;
