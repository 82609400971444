import { mainUrl } from './apiSettings';

/* MURAL */
export const ADMIN_MURAL_DELETE_POST = (muralId) => `${mainUrl}/admin/mural/${muralId}`;

/* TOKENS */
export const ADMIN_TOKEN_ADD_DATA = `${mainUrl}/admin/token`;
export const ADMIN_TOKEN_DELETE = `${mainUrl}/admin/token/delete`;
export const ADMIN_TOKEN_EDIT = `${mainUrl}/admin/token/edit`;

/* QUIZZ CHOISES
*  Main request
*  POST - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzChoises
*  PATCH - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzChoises
*  DELETE - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzChoises/{uniqueId}
*
*  Ordering:
*  PATCH - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzChoises/order
*
*  Formatted Answers
*  GET - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzChoises/graphs/{quizzId}
* */
export const ADMIN_QUIZZ_CHOISES = `${mainUrl}/admin/quizzChoises`;
export const ADMIN_QUIZZ_REMOVE = (uniqueId) => `${mainUrl}/admin/quizzChoises/${uniqueId}`;
export const ADMIN_QUIZZ_ANSWERS = (uniqueId) => `${mainUrl}/admin/quizzChoises/answers/${uniqueId}`;
export const ADMIN_QUIZZ_ORDER = `${mainUrl}/admin/quizzChoises/order/`;

export const ADMIN_QUIZZ_OPEN_GRAPHS = (uniqueId) => `${mainUrl}/admin/quizzChoises/graphs/${uniqueId}`;

/* QUIZZ OPEN */
export const ADMIN_QUIZZ_OPEN = `${mainUrl}/admin/quizzOpen`;
export const ADMIN_QUIZZ_OPEN_REMOVE = (uniqueId) => `${mainUrl}/admin/quizzOpen/${uniqueId}`;
export const ADMIN_QUIZZ_OPEN_ANSWERS = (quizzId) => `${mainUrl}/admin/quizzOpen/answers/${quizzId}`;
export const ADMIN_QUIZZ_OPEN_ANSWERS_VALIDATION = `${mainUrl}/admin/quizzOpen/answers/validation`;


// Endpoint: POST - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzOpen
// Endpoint: PATCH - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzOpen
// Endpoint: DELETE - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzOpen/{uniqueId}
// Endpoint: GET - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzOpen/answers/{uniqueId}
// Endpoint: POST - https://wsm5ods313.execute-api.sa-east-1.amazonaws.com/dev/admin/quizzOpen/answers/validation

/* CONTEÚDO */
// POST - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/content
// GET - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/content
// PATCH - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/content/{uniqueId}
// DELETE - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/content/{uniqueId}
// PATCH - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/content/order

export const CONTENT_POST = `${mainUrl}/admin/content`;
export const CONTENT_GET = `${mainUrl}/admin/content`;
export const CONTENT_PATCH = `${mainUrl}/admin/content`;
export const CONTENT_DELETE = (uniqueId) => `${mainUrl}/admin/content/${uniqueId}`;
export const CONTENT_ORDER = `${mainUrl}/admin/content/order`;

/* SPONSOR */
//  GET - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/sponsor
//  POST - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/sponsor
//  PATCH - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/sponsor
//  DELETE - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/sponsor/{uniqueId}

export const SPONSOR_GET = `${mainUrl}/admin/sponsor`;
export const SPONSOR_POST = `${mainUrl}/admin/sponsor`;
export const SPONSOR_EDIT = `${mainUrl}/admin/sponsor`;
export const SPONSOR_DELETE = (uniqueId) => `${mainUrl}/admin/sponsor/${uniqueId}`;

/* SURVEY */
// GET - https://zztxdj5j4j.execute-api.sa-east-1.amazonaws.com/dev/admin/survey

export const SURVEY_GET = `${mainUrl}/admin/survey`;
