import React from 'react';
import { View, Image, Text } from 'react-native-web';
import PropTypes from 'prop-types';
import {stringIsEmpty} from "../../helpers/stringCheker";
import {Button} from "..";

class ImageSelection extends React.Component {

    state = {
        imageToRender: null,
        imageStripped: null
    };

    renderImagePreview = () => {
        let { data, previewUseUrl } = this.props;
        if(stringIsEmpty(data)) return;

        if (previewUseUrl)
            data = `data:image/jpeg;base64,${data}`;

        return (
            <Image
                source={{ uri: data}}
                style={{ height: 300, width: '100%', backgroundColor: 'black' }}
                resizeMode={'contain'}
            />
        )
    };

    onFileChange = (e) => {
        if (e.target.files.length === 0) return;

        const file = e.target.files[0];

        let reader = new FileReader();
        reader.onloadend = () => {
            let base64result = reader.result.split(',')[1];
            this.props.onImageChange(base64result);
        };

        reader.readAsDataURL(file);
    };

    onImageRemove = () => {
        this.props.onImageChange('');
    };

    render() {

        return (
            <View style={{ flexDirection: 'row', margin: 10, padding: 12, width: '100%' }}>

                <View style={{ width: '80%' }}>
                    <Text> Imagen: </Text>
                    <input type={"file"} onChange={this.onFileChange} />

                    { this.renderImagePreview() }

                </View>

                <View style={{ width: '20%' }}>
                    <Button
                        text={'Eliminar'}
                        onPress={this.onImageRemove}
                        containerPropsStyle={{ height: 30, margin: 12 }}
                        textPropsStyle={{ fontSize: 16 }}
                    />
                </View>

            </View>
        )
    }
}

ImageSelection.propTypes = {
    onImageChange: PropTypes.func.isRequired,
    data: PropTypes.string.isRequired,
    previewUseUrl: PropTypes.bool.isRequired
};

export { ImageSelection }
