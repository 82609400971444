import React from 'react';
import {
    View,
    Text,
    StyleSheet
} from 'react-native';
import PropTypes from 'prop-types';

const TitleDescriptionText = ({title, description, titlePropsStyle, descriptionPropsStyle}) => {
    return (
        <View>
            <View>
                <Text style={[styles.titleStyle, titlePropsStyle]}>
                    {title}
                </Text>
            </View>
            <View>
                <Text style={[styles.descriptionStyle, descriptionPropsStyle]}>
                    {description}
                </Text>
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    titleStyle: {
        fontSize: 26,
        fontWeight: 'bold'
    },
    descriptionStyle: {
        fontSize: 16
    }
});

TitleDescriptionText.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    titlePropsStyle: PropTypes.object,
    descriptionPropsStyle: PropTypes.object
};

export {TitleDescriptionText};