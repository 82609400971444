export default {

    backgroundColor: '#DCDEEA',
    navBarColor: '#005CAD',

    mainColor: '#32678F',

    errorBackground: 'red',
    errorText: '#fff',

    warningBackground: '#EAEB5E',
    warningText: '#666804',

    inputFontColor: '#32678F',
    inputLineColor: '#32678F',

    drawerBackgroundColor: '#001939',
    drawerLineColor: '#000a1d',

    borderColor: '#32678F',

    gridHeader: "#005CAD",
    gridCell: "#ffffff",
};

export const ColorsList = [
    'red',
    'green',
    'blue',
    'orange',
    'yellow',
    'indigo',
    'violet'
];
