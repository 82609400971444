import React from 'react';
import {
    Text,
    StyleSheet
} from 'react-native-web';

const Title = ({ title, description, propsStyle }) => {

    return <Text style={propsStyle}> <Text style={styles.title} > {`${title}`} </Text> {description} </Text>
};

const styles = StyleSheet.create({
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
});

export {Title};
