import {
    POST_UPDATE_POSTS,
    POST_UPDATE_POSTS_FAIL,
    POST_UPDATE_POSTS_SUCCESS
} from './types';

import { MURAL_QUERY } from '../../api/apiEndpoints';
import {MURAL_GET} from "../../screens/mural/api/muralEndpoints";

//Action to get all the posts inside the db.
export const getPosts = (authToken) => {
    return (dispatch) => {
        dispatch({ type: POST_UPDATE_POSTS, payload: 'updating posts' });

        console.log(MURAL_GET);

        fetch(MURAL_GET, {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        })
            .then(response => response.json())
            .then(responseJson => {
                console.log(responseJson);
                getPostSuccess(dispatch, responseJson.data);
            })
            .catch(error => console.log(error));
    };
};

//Action to inform that the get post failed.
const getPostFailed = (dispatch, errorText) => {
    dispatch({
        type: POST_UPDATE_POSTS_FAIL,
        payload: errorText
    });
};

//Action to inform that the get post success
const getPostSuccess = (dispatch, items) => {
    dispatch({
        type: POST_UPDATE_POSTS_SUCCESS,
        payload: items
    });
};
