import React from 'react';
import {
    View,
    Dimensions
} from 'react-native';
import {withRouter} from 'react-router-dom';

import {ButtonImageText} from "..";

import Colors from "../../constants/Colors";
import { drawerConfig } from './DrawerConfig';

class Drawer extends React.Component {

    state = {
        dimensions: null
    };

    handleResize = (dimensions) => {
        this.setState({ dimensions: dimensions });
    };

    componentDidMount() {
        Dimensions.addEventListener("change", this.handleResize)
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.handleResize);
    }

    render() {
        return(
            <View style={{
                width: 180,
                height: Dimensions.get('window').height - 50,
                backgroundColor: Colors.drawerBackgroundColor
            }}>

                {
                    drawerConfig.map((item, index) => {
                        return (
                            <ButtonImageText
                                key={index.toString()}
                                text={item.name}
                                onPress={() => this.props.history.push(item.route)}
                            />
                        )
                    })
                }

                {/*<ButtonImageText*/}
                {/*    text={'Notícias'}*/}
                {/*    onPress={() => this.props.history.push('/news')}*/}
                {/*/>*/}

                {/*<ButtonImageText*/}
                {/*    text={'Mural'}*/}
                {/*    onPress={() => this.props.history.push('/mural')}*/}
                {/*/>*/}

                {/*<ButtonImageText*/}
                {/*    text={'Agenda'}*/}
                {/*    onPress={() => this.props.history.push('/agenda')}*/}
                {/*/>*/}

                {/*<ButtonImageText*/}
                {/*    text={'Quizz Alternativas'}*/}
                {/*    onPress={() => this.props.history.push('/quizz')}*/}
                {/*/>*/}

            </View>
        )
    }
}

export default withRouter(Drawer);
