import React, {useMemo} from 'react';
import Table from "../../components/table/Table";
import {Text, TouchableOpacity} from "react-native-web";

const EverydayTable = props => {

    const memoData = useMemo(() => props.data, [props.data]);
    const columns = useMemo(
        () => [
            {
                Header: "Vendor ID",
                accessor: "vendorId",
                Cell: ({ cell: { value } }) => {
                    return (
                        <TouchableOpacity
                            onPress={() => {
                                props.history.push(`/everydayexcellence/details?vendorId=${value}`)
                            }}
                        >
                            <Text style={{ color: 'blue', textDecorationLine: 'underline', textAlign: 'center' }}>
                                {value}
                            </Text>
                        </TouchableOpacity>
                    )
                }
            },
            {
                Header: "Email",
                accessor: "userEmail"
            },
            {
                Header: "Categoria 1",
                accessor: "categoryOnePoints"
            },
            {
                Header: "Categoria 2",
                accessor: "categoryTwoPoints"
            },
            {
                Header: "Categoria 3",
                accessor: "categoryThreePoints"
            },
            {
                Header: "Total",
                accessor: "totalPoints"
            }
        ],
        [props.data]
    )

    return (
        <>
            {
                props.data.length > 0 &&
                <Table
                    columns={columns}
                    data={memoData}
                    havePagination={true}
                    filterSettings={{
                        haveFilter: true,
                        filterKey: 'vendorId',
                        useGlobalFilter: true,
                    }}
                />
            }
        </>
    )
}

export default EverydayTable;
