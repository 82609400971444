import React from 'react';
import {
    View,
    Text,
    StyleSheet
} from 'react-native-web';
import PropTypes from 'prop-types';

import Modal from 'modal-enhanced-react-native-web';
import {Button} from "..";

const ConfirmationDialogue = (props) => {

    const {
        isModalVisible,
        onClose,
        onConfirm,
        descriptionText,
    } = props;

    return (
        <Modal
            isVisible={isModalVisible}
            style={{
                flex: 1,
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    maxWidth: 400,
                    backgroundColor: 'white',
                    borderRadius: 8,
                    padding: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >

                <Text
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: 22,
                        marginBottom: 12
                    }}
                >
                    Confirmar eliminación
                </Text>

                <Text
                    style={{
                        textAlign: 'center',
                        marginBottom: 12,
                    }}
                >
                    { descriptionText }
                </Text>

                <View
                    style={{ flexDirection: 'row', justifyContent: 'space-around' }}
                >
                    <Button
                        text={'Confirmar'}
                        onPress={onConfirm}
                        containerPropsStyle={[{
                            backgroundColor: 'red',
                            borderColor: 'red',
                        }, styles.buttonContainer]}
                        textPropsStyle={styles.buttonText}
                    />

                    <Button
                        text={'Cancelar'}
                        onPress={onClose}
                        containerPropsStyle={styles.buttonContainer}
                        textPropsStyle={styles.buttonText}
                    />
                </View>
            </View>
        </Modal>
    )
};

const styles = StyleSheet.create({
    buttonContainer: {
        height: 40,
        margin: 10
    },
    buttonText: {
        fontSize: 14
    }
});

ConfirmationDialogue.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    descriptionText: PropTypes.string.isRequired,
};

export {ConfirmationDialogue};


