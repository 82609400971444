import { authId, authApiRoot, environment } from './apiSettings';

/* Autenticação */

/*Post
  email: '',
  password: ''
*/
export const AUTHENTICATION_POST = `https://${authApiRoot}.execute-api.sa-east-1.amazonaws.com/dev/auth/${authId}`;

/*Post
  email: '',
  password: '',
  ...rest
*/
// export const REGISTER_POST = `https://${authApiRoot}.execute-api.sa-east-1.amazonaws.com/${enviroment}/auth/${authId}/register`;

export const RECOVER_DEFINE_INFORMATION = `https://${authApiRoot}.execute-api.sa-east-1.amazonaws.com/${environment}/auth/${authId}/recover`;

/**
 * @return {string}
 */
export const RECOVER_GET_CODE = (email) =>  {
    return `https://${authApiRoot}.execute-api.sa-east-1.amazonaws.com/${environment}/auth/${authId}/recover/${email}`;
};
