import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native-web';
import {connect} from 'react-redux';
import sha256 from 'crypto-js/sha256';
import { loginUserPromise, loginUserAsync } from '../redux/actions';
import {ButtonWithSpinner, ConditionalTextRender, InputTextImage} from "../components";
import Colors from "../constants/Colors";

class LoginScreen extends React.Component {

    state = {
        userEmail: '',
        userPassword: '',
        isLoading: false,
        logoutUser: false,
        errorMessage: '',
    };

    handleInputs(type, value) {
        switch (type) {
            case 'email':
                this.setState({userEmail: value.toLowerCase()});
                break;

            case 'password':
                this.setState({userPassword: value});
                break;
        }
    }

    handleLoginRequest = async () => {
        if (this.props.isFetching) {
            return;
        }

        let email = this.state.userEmail;
        let password = this.state.userPassword;
        password = sha256(password).toString();

        try {
            await this.props.loginUserAsync(email, password);
            this.props.history.push('/home');

        } catch (e) {
            console.log(e);
        }
    };

    render() {
        return (
            <View style={{
                flex: 1,
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                    <Image
                        source={require('../img/i9-logo.png')}
                        style={{
                            width: 200,
                            height: 100,
                            marginTop: 20
                        }}
                        resizeMode={'contain'}
                    />

                    <View style={{ marginTop: 20, marginBottom: 6 }}>

                        <Text style={{ maxWidth: 400, marginBottom: 20,  }}>
                            Para acceder al módulo de administración, es necesario crear una cuenta por parte del equipo de soporte de I9Ação, si aún no tiene acceso, ¡comuníquese con Servicio al Cliente solicitando la creación de un nuevo usuario!
                        </Text>

                        <InputTextImage
                            label="Correo electrónico"
                            placeholder="email@email.com.br"
                            onChangeText={(input) => this.handleInputs('email', input)}
                            value={this.state.userEmail}
                            keyboardType={'email-address'}
                            autoCapitalize={'none'}
                            imageSource={require('../img/pointers/bluePointer.png')}
                            propsContainerStyle={styles.inputTextImageContainer}
                            propsImageStyle={styles.inputTextStyle}
                        />

                        <InputTextImage
                            label="Contraseña"
                            secureTextEntry
                            placeholder="contraseña"
                            onChangeText={(input) => this.handleInputs('password', input)}
                            value={this.state.userPassword}
                            autoCapitalize={'none'}
                            imageSource={require('../img/pointers/bluePointer.png')}
                            propsContainerStyle={styles.inputTextImageContainer}
                            propsImageStyle={styles.inputTextStyle}
                        />
                    </View>

                    <ConditionalTextRender
                        text={this.props.errorMessage}
                        textPropsStyle={{ color: 'red' }}
                    />

                    <ButtonWithSpinner
                        isLoading={this.props.isFetching}
                        text={'Iniciar sesión'}
                        onPress={() => this.handleLoginRequest()}
                        containerPropsStyle={{
                            width: 260,
                            height: 40,
                            alignSelf: 'center'
                        }}
                        textPropsStyle={{
                            fontSize: 22
                        }}
                    />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    inputTextStyle: {
        height: 10,
        width: 10
    },
    inputTextImageContainer: {
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderRadius: 0,
        borderColor: Colors.mainColor,
        marginLeft: 10,
        marginRight: 10,
    }
});

const mapStateToProps = state => {
    return {
        isFetching: state.auth.isFetching,
        errorMessage: state.auth.errorMessage,
    }
};

export default connect(mapStateToProps, {loginUserPromise, loginUserAsync})(LoginScreen);
