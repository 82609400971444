import Colors from './Colors';

export const inputContainer = {
    width: 500,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderRadius: 0,
    borderColor: Colors.mainColor,
};

export const labelStyle = {
    color: Colors.inputFontColor,
    fontSize: 14,
    margin: 5
};

export const rowStyle = {
    width: 500,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
    marginTop: 10,
    borderWidth: 0.4,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderRadius: 0,
    borderColor: Colors.borderColor
};


export const rowText = {
    color: Colors.inputFontColor,
    fontSize: 14,
    margin: 5
};

export const cardButtonContainer = {
    height: 30,
    width: 100,
    borderRadius: 4,
    marginBottom: 8
};