import React from 'react';
import {
    View,
    Text
} from 'react-native-web';
import PropTypes from 'prop-types';

import { Button, SpinnerControl } from './';
import {cardButtonContainer} from "../constants/Styles";

const Refresh = (props) => {

    const { onRefreshClick, isFetching: isFetching, fetchDate } = props;

    return (
        <View style={{ flexDirection: 'row', margin: 10 }}>
            <Button
                text={'Actualizar'}
                onPress={onRefreshClick}
                containerPropsStyle={cardButtonContainer}
                textPropsStyle={{fontSize: 16}}
            />

            {
                isFetching === true &&
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text> Actualizando </Text>
                    <SpinnerControl isFetching={isFetching} size={'small'} />
                </View>
            }

            {
                (fetchDate !== null && fetchDate !== undefined) &&
                <View style={{ margin: 10 }}>
                    <Text> { `Última actualización realizada en ${fetchDate}.` } </Text>
                </View>
            }

        </View>
    )
};

Refresh.propTypes = {
    onRefreshClick: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    fetchDate: PropTypes.string
};

export { Refresh };


