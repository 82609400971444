import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGIN_USER,
    LOGOUT_USER,
} from './types';

import { AUTHENTICATION_POST } from '../../api/authEndpoints';
import post from "../../api/methods/post";


export const logoutUser = () => {
    return {
        type: LOGOUT_USER
    };
};

export const loginUserAsync = (email, password) => {
    return async (dispatch) => {
        // console.log(LOGIN_POST)
        dispatch({ type: LOGIN_USER });

        const body = {
            email: email,
            password: password
        };

        try {
            let authResponse = await post(AUTHENTICATION_POST, body);
            let authResponseJson = await authResponse.json();

            const generalData = JSON.parse(authResponseJson.data);
            const { authData, data} = generalData;

            loginUserSuccess(dispatch, authData, data, email, password);

            return authResponseJson;

        } catch (e) {

            console.log('auth error', e);
            loginUserFailed(dispatch, 'error');
            throw(e);
        }
    }
};

export const loginUserPromise = (email, password) => (dispatch) => new Promise(function (resolve, reject) {
    dispatch({ type: LOGIN_USER });

    if (email === '' || password === '') {
        return loginUserFailed(dispatch, 'Credenciais em branco.');
    }

    const body = {
        email: email,
        password: password
    };

    fetch(AUTHENTICATION_POST, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            if (response.status !== 200) {
                throw (response);
            }

            return response.json();
        })
        .then(responseJson => {
            const data = JSON.parse(responseJson.data);
            const authData = data.authData;
            const userData = data.data;

            // getContactListSuccess(dispatch, data.data.contacts);
            loginUserSuccess(dispatch, authData, userData, email, password);
            resolve(userData);
        })
        .catch(error => {
            loginUserFailed(dispatch, 'Erro de autenticação');
            reject({ code: 'Erro de autenticação' });
        })
});

const loginUserFailed = (dispatch, errorText) => {
    dispatch({
        type: LOGIN_USER_FAILED,
        payload: errorText
    });
};

const loginUserSuccess = (dispatch, authData, userData, email, password) => {
    dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: {
            authData: authData,
            email: email,
            password: password,
            userData: userData
        }
    });
};
