import React from 'react';
import {View, ActivityIndicator} from 'react-native';
import PropTypes from 'prop-types';

const Spinner = ({size, viewPropsStyle}) => {
    return (
        <View style={[styles.spinnerStyle, viewPropsStyle]}>
            <ActivityIndicator size={size || 'large'}/>
        </View>
    );
};

const styles = {
    spinnerStyle: {
        height: 100,
        alignSelf: 'stretch',
        // backgroundColor: '#293C68FF',
        // borderRadius: 40,
        // borderWidth: 1,
        // borderColor: '#18144AFF',
        // marginLeft: 45,
        // marginRight: 45,
        justifyContent: 'center',
        alignItems: 'center'
    }
};

Spinner.propTypes = {
    size: PropTypes.string,
    viewPropsStyle: PropTypes.object
};

export {Spinner};