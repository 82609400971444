import React from 'react';
import {View, Text, ScrollView} from 'react-native-web';
import NavBar from "../../components/NavBar";
import queryString from "query-string";
import get from "../../api/methods/get";
import {API_KEY, GET_MONTH_DETAIL} from "./constants.";
import {Bold, Card, SpinnerControl, Title} from "../../components";

class EverydayExcellenceMonthDetail extends React.Component {

    state = {
        isFetching: false,

        vendorId: null,
        date: null,

        dataToRender: [],
    }

    handleFetching = async () => {
        if (this.state.isFetching) return;
        this.setState({ isFetching: true });

        try {

            let result = await get(GET_MONTH_DETAIL(this.state.vendorId, this.state.date), null, API_KEY);
            let resultJson = await result.json();

            this.setState({ dataToRender: resultJson.data });

        } catch (e) {
            console.log(e);
        }

        this.setState({ isFetching: false });
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        console.log(values);

        this.setState({ vendorId: values.vendorId, date: values.date }, this.handleFetching)
    }

    render() {
        return (
            <NavBar title={`Detalles - ${this.state.date} - ${this.state.vendorId}`} showBackButton={true}>
                <ScrollView>
                    <SpinnerControl isFetching={this.state.isFetching} text={'Cargando datos del mes.'} />

                    <View style={{ padding: 16 }}>
                        {
                            this.state.dataToRender.map(item => {
                                console.log(item);
                                return (
                                    <Card containerProps={{ marginBottom: 12 }}>
                                        <Bold label={"Categoría: "} description={item.categoria} />
                                        <Bold label={"Sub Categoría: "} description={item.subCategoria} />
                                        <Bold label={"Puntos: "} description={item.points} />
                                        <div style={{ height: 22 }} />

                                        <Title title={'Detalhes'} />
                                        <View style={{ padding: 4 }}>
                                            {
                                                item.dataType === "Categoria01_CesarMarinho" &&
                                                <Text style={{ fontSize: 16 }}>
                                                    {
                                                        `${item.awardTitle} \n` +
                                                        `${item.awardMessage} \n`
                                                    }
                                                </Text>
                                            }

                                            {
                                                item.dataType === "Categoria01_MyMoment" &&
                                                <Text style={{ fontSize: 16 }}>
                                                    {
                                                        `${item.awardReason} \n` +
                                                        `${item.awardType} \n` +
                                                        `${item.awardMessage} \n \n` +
                                                        `De: ${item.from} \n`
                                                    }
                                                </Text>
                                            }

                                            {
                                                item.dataType === "Categoria02_GrupoTrabalho" &&
                                                <Text style={{ fontSize: 16 }}>
                                                    {
                                                        `Grupo: ${item.group} \n` +
                                                        `Nota: ${item.cycleNote} \n`
                                                    }
                                                </Text>
                                            }

                                            {
                                                item.dataType === "Categoria02_EventosExcellence" &&
                                                <Text style={{ fontSize: 16 }}>
                                                    {
                                                        `${item.reason} \n`
                                                    }
                                                </Text>
                                            }

                                            {
                                                (item.dataType === "Categoria03_TreinamentoPresenciais" || item.dataType === "Categoria03_TreinamentoELearnings") &&
                                                <Text style={{ fontSize: 16 }}>
                                                    {
                                                        `${item.curso}`
                                                        // `${item.session === null ? "" : item.session} \n`
                                                    }
                                                </Text>
                                            }
                                        </View>
                                    </Card>
                                )
                            })
                        }
                    </View>
                </ScrollView>
            </NavBar>
        )
    }
}

// PK: "USER#V349446"
// SK: "ITEM#48d2c5c0-86a5-11ea-8239-759edce7ede3"
// categoria: "Eu me capacito para atuar com excelência"
// categoryType: "categoria_03"
// curso: "ADP BR ADP Brazil: BE Culture Plan Básico (treinamento com Instrutor)"
// dataType: "Categoria03_TreinamentoPresenciais"
// date: "2019-11"
// points: 10
// session: "Session 12 - ADP BR BE Culture Plan Básico (Treinamento com instrutor)"
// subCategoria: "Treinamentos Presenciais"

export default EverydayExcellenceMonthDetail;
